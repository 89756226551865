interface ImageFile {
  url: string
}

interface ImageFields {
  description: string
  file: ImageFile
  title: string
  fields: ImageFields
  sys?: {
    id: string
  }
}

interface Image {
  fields: ImageFields
}

interface Fields {
  image: ImageFields
  label: string
  reference?: string
  referenceWeb?: string
  referenceType: string
}

export interface ImageBanner {
  fields: Fields
  image: Image
  label: string
  reference?: string
  referenceWeb?: string
  referenceType: string
  unimarcEnabledLevels?: string[]
}

interface Asset {
  fields: ImageFields
  sys: {
    id: string
  }
}

export interface ClubUnimarcStaticBanner {
  includes: {
    Entry: ImageBanner[]
    Asset: Asset[]
  }
}

export interface BannersClubAhorro {
  height: number
  idFormato: number
  imageBanner: ImageBanner[]
  label: string
  layout: string
  subtitle: string
  title: string
  sectionIcon?: Asset
}

export interface FeatureContentItem {
  description?: string
  items: any[]
  itemFormat: FeatureContentItemFormat
  label: string
  layout: string
  subtitle: string
  sectionIcon: Asset
  textButton: string
  title: string
}

export interface BannersCarrousel {
  image: string
  img: string
  onRedirect: () => void
}

export enum FeatureContentItemFormat {
  Coupons = 'cupones',
  FrequentlyAskedQuestion = 'preguntas frecuentes',
  MembershipButton = 'boton membresia',
  SocialMediaContact = 'contacto social media',
  CustomizedOffers = 'ofertas personalizadas',
  InteractiveTermsAndConditions = 'terminos interactivos'
}

export enum ClubUnimarcBannerLayout {
  Banner = 'banner',
  Carousel = 'carousel'
}

export enum ClubUnimarcBannerLabels {
  CouponsBlock = 'componente-vacio-cupones'
}
