import {
  getContentfulDesktopImage,
  getContentfulImageCategories,
  trigger,
  useContentful,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { OFFERS_URL } from 'shared/utils/constanst'

/**
 * Custom hook for managing the SearchHeaderWrapper component state and functionality.
 * @returns {Object} An object containing the following properties:
 * - categoriesDataImage - Processed category images from Contentful
 * - categoriesBannerImage - Processed banner images from Contentful
 * - offersMobile - Object containing offer URL and image for mobile devices
 * - setIsVisible - Function to update visibility state
 * - isVisibleClearIcon - State indicating whether the clear icon should be visible
 * - ref - Reference to the component element for click outside detection
 * - isFocused - State indicating whether the search input is focused
 * - setIsFocused - Function to update the focus state
 * - setIsVisibleClearIcon - Function to update the visibility of the clear icon
 */
export const useSearchHeaderWrapper = () => {
  const router = useRouter()
  const ref = useRef(null)
  const { isMobileV2: isMobile } = useMobile()

  const [isVisibleClearIcon, setIsVisibleClearIcon] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE
    }
  })
  const offersLink = useContentful({
    id_contentful: 'offersLink',
    options: {
      'sys.contentType.sys.id[in]': 'home',
      'sys.id[in]': OFFERS_URL
    }
  })
  const imgOfOffers = useContentful({
    id_contentful: 'offers_img',
    options: {
      'fields.title[in]': 'Ofertas mobile'
    },
    reactQuery: {
      enabled: isMobile
    },
    type: 'assets'
  })

  const categoriesDataImage =
    categoriesApp?.data?.['items']?.length > 0
      ? getContentfulImageCategories(categoriesApp?.data)
      : {}

  const categoriesBannerImage =
    categoriesApp?.data?.['items']?.length > 0
      ? getContentfulDesktopImage(categoriesApp?.data)
      : {}
  const linkOffers =
    offersLink.data?.['includes']?.['Entry']?.[0]?.fields?.text ||
    process.env.NEXT_PUBLIC_OFFERSURL

  const offersMobile = {
    url: linkOffers,
    img: imgOfOffers.data?.['items']?.[0]?.fields?.file?.url
  }

  const setIsVisible = (isOpen: boolean) => {
    trigger({
      eventType: 'headerWrapper',
      data: { isOpen }
    })
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false)
    }
  }

  // This useEffect monitors changes in the suggestions property from the router query.
  // When suggestions is present, it updates the component's state to:
  // - Show the "clear" icon (e.g., for resetting the input or suggestions),
  // - Hide DropDownSearch,
  // - Focus the input or an associated field for user interaction.
  useEffect(() => {
    // Check if suggestions exists in the router query
    if (router?.query?.suggestions) {
      // Show the "clear" icon
      setIsVisibleClearIcon(true)

      // Hide DropDownSearch
      setIsVisible(false)

      // Set the input or related element to focused
      setIsFocused(true)
    }
  }, [router?.query?.suggestions])

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside)

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside)
    }
  }, [])

  return {
    categoriesDataImage,
    categoriesBannerImage,
    offersMobile,
    setIsVisible,
    isVisibleClearIcon,
    ref,
    isFocused,
    setIsFocused,
    setIsVisibleClearIcon
  }
}
