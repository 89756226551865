import { BannerBottomHeader } from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/BottomBanner'
import { useContentful, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { IHeaderBottomBanner } from 'shared/interfaces/HeaderBottomBanner'
import { headerBottomBannerQuery } from 'shared/queries'

export const BannerBottom = () => {
  const router = useRouter()
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const { data } = useContentful({
    id_contentful: 'headerBottomBanner',
    graphQL: {
      query: headerBottomBannerQuery
    }
  }) as IHeaderBottomBanner

  const banners = data?.data?.homeCollection?.items?.[0]?.headerBannerApp
  const hasReference = banners?.referenceType === 'url' && banners?.reference

  const imageUrl = isMobile
    ? banners?.imageMobile?.url
    : banners?.imageDesktop?.url

  const handleClick = () => {
    if (hasReference) {
      router.push(banners?.reference)
    }
  }

  if (!banners) return null
  return (
    <BannerBottomHeader
      imageUrl={imageUrl}
      isMobile={isMobile}
      onClick={hasReference ? handleClick : null}
    />
  )
}
