import React, { useRef, useState } from 'react'
import Link from 'next/link'
import Image from 'next/future/image'
import {
  useLocalAddress,
  useUpdateFromShelf,
  useEvents,
  addToHomeData,
  useGetByPromotion,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import {
  BackboneShelves,
  Column,
  Divider,
  Row,
  ShelfCarousel,
  Spacer,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { useFavoriteProductsManager } from 'shared/hooks/useFavoriteProductsManager'
import {
  useProdutsCarousel,
  useShelvesData
} from 'components/ProductsCarousel/helpers/useProdutsCarousel'
import { transformPromotionItems } from 'shared/helpers'

export interface PromoCombineCarouselProps {
  isMobile?: boolean
  customItemToSlide?: number
  customItemToShow?: number
  ean?: string
  promotionId?: string
  handleOpenMyListsModal?: (item: IProductInList) => void
  onClickProduct?: () => void
  conditionTooltip?: (isAdded?: boolean) => void
  addQuantity?: () => void
  removeQuantity?: () => void
}

export const PromoCombineCarouselComponent = ({
  isMobile,
  customItemToSlide,
  customItemToShow,
  handleOpenMyListsModal,
  ean,
  promotionId,
  addQuantity,
  removeQuantity,
  onClickProduct,
  conditionTooltip
}: PromoCombineCarouselProps) => {
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const { hasLocalAddress } = useLocalAddress()
  const { data, isLoading } = useGetByPromotion(promotionId)
  const { availableProducts = [] } = data || {}
  const { positionContainer, itemsToShow, itemsToSlide, spacerBelow } =
    useProdutsCarousel({ isMobile })
  const { handleToggleFavorite, isProductFavorite, ToastFavoriteUI } =
    useFavoriteProductsManager()

  const carouselRef = useRef(null)

  const products = transformPromotionItems(availableProducts)?.filter(
    (product) => {
      return product?.['ean'] !== ean
    }
  )

  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading,
    products,
    isProductCarousel: true,
    hasLocalAddress
  })

  const handleOnAddQuantity = (props) => {
    handleAdd(props)
    conditionTooltip(true)
    addQuantity()
  }
  const handleOnRemoveQuantity = (props) => {
    handleRemove(props)
    conditionTooltip(false)
    removeQuantity()
  }

  const handleOnAddFirst = (props) => {
    handleOnClick && handleOnClick(props)
    conditionTooltip(true)
    addQuantity()
  }

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  const productsMemo = useShelvesData(shelvesData, isMobile, true) || []

  const carouselShelfData = {
    positionContainer,
    productsMemo,
    itemsToShow: customItemToShow || itemsToShow,
    itemsToSlide: customItemToSlide || itemsToSlide,
    spacerBelow,
    handleOnClick: handleOnAddFirst,
    handleAdd: handleOnAddQuantity,
    handleRemove: handleOnRemoveQuantity,
    handleChange
  }

  const handlePDPShelf = ({ ref, shelf }) => {
    addToHomeData({
      ref,
      promoData: { ...shelf, promoTitle: '' },
      isShelf: true
    })
  }

  if (isLoading) {
    return (
      <BackboneShelves
        shelvesQuantityDesktop={4}
        shelvesQuantityMobile={2}
      />
    )
  }

  if (!isValidArrayWithData(productsMemo)) {
    return null
  }

  return (
    <>
      {isValidArrayWithData(products) && (
        <>
          <ToastFavoriteUI isMobile={isMobile} />
          <Row isWrap>
            {isMobile ? (
              <Column padding='0 20px 0 10px'>
                <Divider
                  borderHeight='3xs'
                  color={getGlobalStyle('--color-neutral-gray-divider')}
                  verticalMargin={26}
                />
                <Title
                  color={getGlobalStyle('--color-text-black')}
                  customFontSize='16px'
                  fontWeight='medium'
                  headingLevel='h4'
                  text='Combina la promo'
                />
              </Column>
            ) : (
              <>
                <Divider
                  borderHeight='3xs'
                  color={getGlobalStyle('--color-neutral-gray-divider')}
                  verticalMargin={26}
                />
                <Title
                  color={getGlobalStyle('--color-text-black')}
                  customFontSize={getGlobalStyle('--font-size-titles-md')}
                  fontWeight='semibold'
                  headingLevel='h4'
                  text='Combina la promo'
                />
                <Spacer.Horizontal size={8} />
              </>
            )}
            <Row
              justifyContent='center'
              margin='0 0 12px'
              maxWidth='1120px'
            >
              {isLoading ? (
                <BackboneShelves
                  shelvesQuantityDesktop={5}
                  shelvesQuantityMobile={2}
                />
              ) : (
                <Column>
                  <Row
                    padding='10px'
                    ref={carouselRef}
                  >
                    <ShelfCarousel
                      carouselProps={{
                        infinite: true,
                        autoPlay: false,
                        shouldResetAutoplay: false,
                        partialVisible: true
                      }}
                      catchShelfRef={handlePDPShelf}
                      containerProps={carouselShelfData.positionContainer}
                      handleOnClick={carouselShelfData.handleOnClick}
                      handleOpenMyListsModal={handleOpenMyListsModal}
                      isMobile={isMobile}
                      isProductFavorite={isProductFavorite}
                      items={carouselShelfData.productsMemo}
                      itemsToShow={carouselShelfData.itemsToShow}
                      linkWrapper={Link}
                      nextImage={Image}
                      onClickProduct={onClickProduct}
                      onToggleFavorite={handleToggleFavorite}
                      quantityButtonProps={{
                        handleAdd: carouselShelfData.handleAdd,
                        handleRemove: carouselShelfData.handleRemove,
                        handleChange: carouselShelfData.handleChange
                      }}
                      quantityButtonStyles={{
                        fontSize: 'md',
                        margin: '0px',
                        maxHeight: '30px',
                        maxWidth: '163px',
                        sizeCircle: 22
                      }}
                      showAddToFavoriteButton={true}
                      showAddToListButton={true}
                      showArrows={!isMobile}
                      showCouponButton={false}
                      showTooltip={false}
                      slidesToSlide={carouselShelfData.itemsToSlide}
                    />
                  </Row>
                </Column>
              )}
            </Row>
          </Row>
        </>
      )}
    </>
  )
}

function handleCarousel(prevState, nextState) {
  return prevState.clusterData === nextState.clusterData
}
export const PromoCombineCarousel = React.memo(
  PromoCombineCarouselComponent,
  handleCarousel
)
