import { Row } from '@smu-chile/pkg-unimarc-components'
import { FormRow as FormRowContent } from 'shared/interfaces/Form'
import { FormField } from '../FormField'

interface FormRowProps {
  formData: { [key: string]: [key: string] }
  formRow: FormRowContent
  handleInputChange?: (
    sectionId: string,
    fieldId: string,
    value: unknown
  ) => void
  sectionId: string
  labelsColor: string
}

export const FormRow = ({
  formData,
  formRow,
  handleInputChange,
  sectionId,
  labelsColor
}: FormRowProps) => {
  return (
    <>
      <Row
        gap={8}
        margin='12px 0px'
      >
        {formRow.formFields.map((formField) => {
          return (
            <FormField
              formData={formData}
              formField={formField}
              handleInputChange={handleInputChange}
              key={`${formRow.name}-${formField.formfieldId}`}
              labelsColor={labelsColor}
              sectionId={sectionId}
            ></FormField>
          )
        })}
      </Row>
    </>
  )
}
