import { useRouter } from 'next/router'
import { Column, Picture, Row } from '@smu-chile/pkg-unimarc-components'
import {
  addToHomeData,
  isValidArrayWithData,
  promosClick,
  sleep,
  useAndesMLClickEvent
} from '@smu-chile/pkg-unimarc-hooks'
import styles from './SponsoredBanner.module.css'
import { useMemo } from 'react'

interface IBanner {
  imageUrl?: string
  redirectUrl?: string
  onClick?: () => void
}

interface SponsoredBannerProps {
  content?: {
    imageBanner?: {
      adId?: string
      campaignId?: string
      imageUrl?: string
      providerMetaData?: {
        effective_ad_cost?: string
        device_type?: string
      }
      redirectUrl?: string
      searchedCategory?: string
      searchText?: string
      slot?: string
    }[]
  }
  isMobile?: boolean
}

export const SponsoredBanner = ({
  content,
  isMobile
}: SponsoredBannerProps) => {
  const router = useRouter()
  const { handleClickOnAndesMLBanner } = useAndesMLClickEvent()
  const gap = isMobile ? '10px' : '16px'

  const sendPromoData = ({ index, data, ref }) => {
    const promoData = {
      locationId: index,
      promotionId: data?.adId,
      creativeSlot: 'HomeGuinchaDuo'
    }

    addToHomeData({ ref, promoData })
  }

  const banners: [string, IBanner][] = useMemo(() => {
    return (
      content?.imageBanner.map((banner, index) => {
        return [
          `banner_${banner.slot}`,
          {
            imageUrl: banner?.imageUrl,
            redirectUrl: banner?.redirectUrl,
            device: banner?.providerMetaData?.device_type,
            onClick: async () => {
              promosClick({
                items: [
                  {
                    index: index,
                    location_id: banner?.slot,
                    promotion_id: banner?.adId,
                    creative_slot: 'HomeGuinchaDuo',
                    quantity: 1
                  }
                ]
              })
              handleClickOnAndesMLBanner({
                adId: banner?.adId,
                campaignId: banner?.campaignId,
                adCost: parseInt(banner?.providerMetaData?.effective_ad_cost)
              })
              await sleep(1500)

              router.push(banner?.redirectUrl)
            }
          }
        ]
      }) || []
    )
  }, [content, handleClickOnAndesMLBanner, router])

  const { guinchaBanners, duoBanners } = useMemo(() => {
    const guincha: IBanner[] = []
    const duo: IBanner[] = []

    banners.forEach(([key, banner]) => {
      if (/HUINCHA/i.test(key)) guincha.push(banner)
      else if (/DUO/i.test(key)) duo.push(banner)
    })

    return { guinchaBanners: guincha, duoBanners: duo }
  }, [banners])

  return (
    <Column
      customClassName={styles['sponsored__container']}
      gap={gap}
      maxWidth='1120px'
    >
      {isValidArrayWithData(guinchaBanners) &&
        guinchaBanners.map((banner, idx) => {
          return (
            <Picture
              catchImgRef={(promoData) => {
                sendPromoData({
                  index: 'ANDES - 1 - 1',
                  data: banner,
                  ref: promoData
                })
              }}
              clickable='pointer'
              height='auto'
              key={`guincha_${idx}`}
              minHeight='auto'
              onClick={banner.onClick}
              src={banner.imageUrl}
              url={banner.redirectUrl}
              width='100%'
            />
          )
        })}

      {isValidArrayWithData(duoBanners) && (
        <Row gap={gap}>
          {duoBanners.map((banner, idx) => {
            return (
              <Picture
                catchImgRef={(promoData) => {
                  sendPromoData({
                    index: `ANDES - 2 - ${idx + 1}`,
                    data: banner,
                    ref: promoData
                  })
                }}
                clickable='pointer'
                height='auto'
                key={`duo_${idx}`}
                onClick={banner.onClick}
                src={banner.imageUrl}
                url={banner.redirectUrl}
                width='100%'
              />
            )
          })}
        </Row>
      )}
    </Column>
  )
}
