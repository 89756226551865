import { useSession } from '@smu-chile/pkg-unimarc-hooks'
import { CarouselCollections } from './Collection'
import { CarouselMyUsual } from './MyUsual'
import { useAddProductToList } from 'shared/utils/hooks'
import { AddToListModalWrapper } from 'components/AddToListModalWrapper'
import { CarouselFromContentful } from 'shared/interfaces/Home'
import { CustomTitles } from 'components/Home'

export interface RenderProductsCarouselProps {
  content: CarouselFromContentful
  index: number
  isMobile: boolean
  customTitles: CustomTitles
  typeCarousel?: string
  type?: string
}

export const RenderProductsCarousel = ({
  content,
  customTitles,
  index,
  isMobile,
  typeCarousel,
  type
}: RenderProductsCarouselProps) => {
  const refType: string = content?.['referenceType']
  const { isLoggedIn } = useSession()

  const {
    createListButton,
    emptyOrangeImage,
    innerWidth,
    listData,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList,
    setOpenModalCreateList
  } = useAddProductToList()

  if (refType?.toLocaleUpperCase() === 'MYUSUALS' && isLoggedIn) {
    return (
      <>
        <AddToListModalWrapper
          createListButton={createListButton}
          emptyOrangeImage={emptyOrangeImage}
          handleAddProductsToList={handleAddProductsToList}
          handleCreateNewList={handleCreateNewList}
          handleOnClosed={handleOnClosed}
          innerWidth={innerWidth}
          listData={listData}
          notificationAddToList={notificationAddToList}
          openModalCreateList={openModalCreateList}
          selectedListName={selectedListName}
          setCreateListButton={setCreateListButton}
          setNotificationAddToList={setNotificationAddToList}
          setOpenModalCreateList={setOpenModalCreateList}
        />

        <CarouselMyUsual
          clusterData={content}
          handleOpenMyListsModal={handleOpenMyListsModal}
          isMobile={isMobile}
          key={index}
          seeAllUrl={`/mis-productos-frecuentes${type && '?type=myOffers'}`}
          titleSizes={customTitles}
          type={type}
        />
      </>
    )
  }
  if (refType?.toLocaleUpperCase() === 'COLLECTION') {
    return (
      <>
        <AddToListModalWrapper
          createListButton={createListButton}
          emptyOrangeImage={emptyOrangeImage}
          handleAddProductsToList={handleAddProductsToList}
          handleCreateNewList={handleCreateNewList}
          handleOnClosed={handleOnClosed}
          innerWidth={innerWidth}
          listData={listData}
          notificationAddToList={notificationAddToList}
          openModalCreateList={openModalCreateList}
          selectedListName={selectedListName}
          setCreateListButton={setCreateListButton}
          setNotificationAddToList={setNotificationAddToList}
          setOpenModalCreateList={setOpenModalCreateList}
        />

        <CarouselCollections
          clusterData={content}
          handleOpenMyListsModal={handleOpenMyListsModal}
          isMobile={isMobile}
          key={index}
          titleSizes={customTitles}
          typeCarousel={typeCarousel}
        />
      </>
    )
  }

  return null
}
