export const transformPromotionItems = (products) => {
  return products.map((product) => {
    return {
      ...product,
      itemId: product.item?.itemId,
      sku: product.item?.sku,
      nameComplete: product.item?.nameComplete,
      ean: product.item?.ean,
      measurementUnit: product.item?.measurementUnit,
      complementName: product.item?.complementName,
      descriptionShort: product.item?.descriptionShort,
      productId: product.item?.productId,
      name: product.item?.name,
      brand: product.item?.brand,
      brandId: product.item?.brandId,
      description: product.item?.description,
      categoryId: product.item?.categoryId,
      provider: product.item?.provider,
      cartLimit: product.item?.cartLimit,
      volume: product.item?.volume,
      container: product.item?.container,
      degrees: product.item?.degrees,
      mormonsGiftcard: product.item?.mormonsGiftcard,
      unitMultiplierUn: product.item?.unitMultiplierUn,
      measurementUnitUn: product.item?.measurementUnitUn,
      unitMultiplier: product.item?.unitMultiplier,
      refId: product.item?.refId,
      measurement_Unit: product.item?.measurement_Unit,
      netContent: product.item?.netContent,
      netContentLevelSmall: product.item?.netContentLevelSmall,
      images: product.item?.images,
      categories: product.item?.categories,
      categorySlug: product.item?.categorySlug,
      collection: product.item?.collection,
      slug: product.item?.slug,
      coupon: product?.coupon,
      promotion: product?.promotion,
      nonfood: product?.nonfood,
      pricedetail: product?.priceDetail
    }
  })
}
