import {
  AxiosRequestHeaders,
  ContentType,
  Entry,
  getContentfulData,
  IEntriesProps,
  postSearchesIntelligence,
  QueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { paginationCal } from 'shared/helpers/paginationCal'
import { IProductsIntelligenceSearchResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsIntelligenceSearch'
import {
  getDataApplyFilters,
  getInitialFilters,
  getQueryKeyToRequest
} from 'shared/helpers'
import { CAMPAING_APP_CHIPS_UNIMARC } from '../constanst'

export interface GetContentOfferProps {
  contentFulUrl: string
  contenType: string
  currentPage: number
  headers: AxiosRequestHeaders
  idFormat: number
}

interface GetContentOffer {
  bannerLegal: IEntriesProps
  clusterId: string
  clusterName?: string
  description?: string
  error: boolean
  offerProductsData: IProductsIntelligenceSearchResponse
  queryClient: QueryClient
  reference?: string
  robotTag?: string
  title: string
  intelliSearchKeyName: string[]
}

interface Collection {
  description?: string
  idCollection: string
  collectionName?: string
  label: string
  pageTitle: string
  robot?: string
  url: string
}

interface ClusterParams {
  clusterNames?: string
  clusterId?: string
}

// prioritize clusterNames over clusterId in collections
export const clusterParams = ({
  clusterNames,
  clusterId
}: ClusterParams): ClusterParams => {
  if (clusterNames) {
    return {
      clusterNames: clusterNames,
      clusterId: ''
    }
  }
  return {
    clusterNames: '',
    clusterId: clusterId
  }
}

export const getContentOffers = async ({
  contentFulUrl,
  contenType,
  currentPage,
  idFormat,
  headers
}: GetContentOfferProps): Promise<GetContentOffer> => {
  const queryClient = new QueryClient()
  const collectionsWebReactQueryName = `contenful_${contenType}` // "Colecciones Web" ContentType
  const contentfulQueryOptions = {
    options: {
      content_type: contenType,
      'fields.idFormat': idFormat
    }
  }
  const page = currentPage
  let error = true
  let offerProductsData: IProductsIntelligenceSearchResponse

  // get collections web data from contentful and save it in query cache
  const getCollectionsWebDataFromContentful = (
    collectionsWebReactQueryName: string
  ) => {
    return queryClient.prefetchQuery(collectionsWebReactQueryName, () => {
      return getContentfulData(contentfulQueryOptions)
    })
  }

  // get banners and legals from contentful
  const getBannerAndLegalsFromContentful = () => {
    return getContentfulData({
      options: {
        content_type: 'plpBannerLegal',
        'fields.idFormato': 1,
        'fields.referenceType': 'collection',
        'fields.referencia': contentFulUrl
      }
    })
  }

  // execute paralell promises
  const getContentFullData = await Promise.all([
    getCollectionsWebDataFromContentful(collectionsWebReactQueryName),
    getBannerAndLegalsFromContentful()
  ])

  const getBannerAndLegals = getContentFullData.pop() as IEntriesProps

  // get previous data from query cache and save it for later
  const collectionsWebData: IEntriesProps = queryClient.getQueryData(
    collectionsWebReactQueryName
  )

  // get collection from contenful's web collection by include id
  const getCollectionsByIncludeId: Collection[] =
    collectionsWebData?.items[0]?.fields?.collection?.map(
      (item: ContentType) => {
        return collectionsWebData?.includes.Entry?.filter((include: Entry) => {
          return include?.sys?.id == item.sys.id
        })?.[0]?.fields
      }
    )

  // filter collection by contentful url
  const findContentFulCollectionByURL: Collection =
    getCollectionsByIncludeId?.find((item: Collection) => {
      return item?.url === contentFulUrl
    })
  const clusterId =
    findContentFulCollectionByURL?.idCollection?.split(':')?.[1] || ''
  const clusterName: string =
    findContentFulCollectionByURL?.collectionName || ''
  const clusterKeyIdentifier =
    findContentFulCollectionByURL?.collectionName ||
    findContentFulCollectionByURL?.idCollection?.split(':')?.[1] ||
    ''
  const reference = findContentFulCollectionByURL?.idCollection
  const clusterIdParameters = clusterParams({
    clusterNames: clusterName,
    clusterId: clusterId
  })

  let intelliSearchKeyName = null
  // verify that collection have idCollection property
  if (findContentFulCollectionByURL?.idCollection) {
    error = false
    const { offset, end } = paginationCal({ page, limit: 50 })
    const initialFilters = getInitialFilters({})
    const { brand, format, category } = getDataApplyFilters(initialFilters)
    const keysToQuery = getQueryKeyToRequest([
      ...initialFilters,
      { value: clusterKeyIdentifier.toString() },
      { value: offset.toString() },
      { value: end.toString() }
    ])
    intelliSearchKeyName = ['searchesIntelligence', keysToQuery]
    await queryClient.prefetchQuery(intelliSearchKeyName, () => {
      return postSearchesIntelligence({
        ...clusterIdParameters,
        from: offset.toString(),
        headers,
        to: end.toString(),
        brands: brand && [brand],
        categories: category,
        format: format && [format]
      })
    })
    offerProductsData = queryClient.getQueryData(intelliSearchKeyName)
    if (offerProductsData.error) error = offerProductsData.error
  }

  return {
    error,
    clusterId: clusterIdParameters?.clusterId,
    clusterName: clusterIdParameters?.clusterNames,
    description: findContentFulCollectionByURL?.description || '',
    offerProductsData: offerProductsData || null,
    queryClient,
    reference,
    robotTag: findContentFulCollectionByURL?.robot || 'index,follow',
    title: findContentFulCollectionByURL?.pageTitle || '',
    bannerLegal: getBannerAndLegals,
    intelliSearchKeyName
  }
}
