import { useState, useEffect, useRef, MutableRefObject } from 'react'
import { NextRouter, useRouter } from 'next/router'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { TFontSize } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontSize'

interface IUseCustomHeaderProps {
  isMobile: boolean
  title: string
  totalQuantity: number | string
  isSuggestionsPage: boolean
  isLoadingFacets: boolean
}
interface IUseCustomHeaderReturn {
  router: NextRouter
  primary: MutableRefObject<HTMLElement>
  innerWidth: number
  showBackBone: boolean
  fontSizeQuantity: TFontSize
  titleText: string
  searchTerm: string
  resultText: string
}

/**
 * A custom hook to manage and return custom header state based on multiple conditions.
 *
 * @param {Object} props - The properties required by the hook.
 * @param {boolean} props.isMobile - Indicates if the user is on a mobile device.
 * @param {string} props.title - The title displayed on the header.
 * @param {number|string} props.totalQuantity - The total quantity of products or results.
 * @param {boolean} props.isSuggestionsPage - Determines if the current page is the suggestions page.
 * @param {boolean} props.isLoadingFacets - Indicates if facets are currently loading.
 * @returns {Object} An object containing router, primary ref, innerWidth, showBackBone,
 * fontSizeQuantity, titleText, searchTerm, and resultText.
 *
 * @example
 * const {
 *   router,
 *   primary,
 *   innerWidth,
 *   showBackBone,
 *   fontSizeQuantity,
 *   titleText,
 *   searchTerm,
 *   resultText
 * } = useCustomHeader({
 *   isMobile: true,
 *   title: "Sample Title",
 *   totalQuantity: 123,
 *   isSuggestionsPage: false,
 *   isLoadingFacets: true
 * })
 */
export const useCustomHeader = ({
  isMobile,
  title,
  totalQuantity,
  isSuggestionsPage,
  isLoadingFacets
}: IUseCustomHeaderProps): IUseCustomHeaderReturn => {
  const router = useRouter()
  const primary = useRef(null)
  const { innerWidth } = useMobile()

  const [showBackBone, setShowBackBone] = useState(false)
  const [firstRender, setFirstRender] = useState(0)

  const fontSizeQuantity = isMobile ? 'sm' : 'base'
  const titleText = isSuggestionsPage ? 'Resultados para' : title
  const searchTerm = `“${title}”`
  const resultText = isSuggestionsPage
    ? `${totalQuantity} productos`
    : `(${totalQuantity} resultados)`

  useEffect(() => {
    if (isLoadingFacets && firstRender === 0 && !showBackBone) {
      setFirstRender(1)
      setShowBackBone(true)
    }
    if (!isLoadingFacets && firstRender === 1 && showBackBone) {
      setShowBackBone(false)
    }
  }, [isLoadingFacets, firstRender, showBackBone])

  return {
    router,
    primary,
    innerWidth,
    showBackBone,
    fontSizeQuantity,
    titleText,
    searchTerm,
    resultText
  }
}
