import { Container, Title } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { BaseContainer } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { FormSection as FormSectionContent } from 'shared/interfaces/Form'
import { FormRow } from '../FormRow'

interface FormSectionProps {
  formData: { [key: string]: [key: string] }
  formSection: FormSectionContent
  handleInputChange?: (
    sectionId: string,
    fieldId: string,
    value: unknown
  ) => void
  labelsColor: string
}

export const FormSection = ({
  formData,
  formSection,
  handleInputChange,
  labelsColor
}: FormSectionProps) => {
  return (
    <>
      <BaseContainer
        alignItems='start'
        flexDirection='column'
      >
        <Container margin='0px 0px 6px'>
          <Title
            color={labelsColor}
            customFontSize={getGlobalStyle(`--font-size-base`)}
            fontWeight='medium'
            headingLevel='h4'
            text={formSection.title}
            textAlign='left'
          ></Title>
        </Container>
        {formSection.formRows.map((formRow) => {
          return (
            <FormRow
              formData={formData}
              formRow={formRow}
              handleInputChange={handleInputChange}
              key={`${formSection.sectionId}-${formRow.name}`}
              labelsColor={labelsColor}
              sectionId={formSection.sectionId}
            ></FormRow>
          )
        })}
      </BaseContainer>
    </>
  )
}
