import { Dispatch, SetStateAction } from 'react'
import Link from 'next/link'
import {
  Backbone,
  Breadcrumbs,
  Container,
  FiltersBarV2,
  Row,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { clickPlp } from '../../shared/utils'
import { GeneralObjectCategories } from './index'
import { useCustomHeaderScrollBehavior } from './helpers/useCustomHeaderScrollBehavior'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import useScrollListener from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/helpers/useScrollListener'
import styles from './CustomHeader.module.css'
import { useCustomHeader } from './helpers/useCustomHeader'

interface CustomHeaderProps {
  breadcrumbData: GeneralObjectCategories[]
  title: string
  totalQuantity: string | number
  isMobile: boolean
  isSuggestionsPage?: boolean
  selectedRadio: string
  hasFacets?: boolean
  isLoadingFacets?: boolean
  handleOpenFiltersModal?: Dispatch<SetStateAction<boolean>>
}

const TextBackBone = ({ width, height }: { width: string; height: number }) => {
  return (
    <Container width={width}>
      <Backbone
        borderRadius='20px'
        height={height}
      />
    </Container>
  )
}

const FiltersBarV2MobileBackBone = ({
  width,
  height
}: {
  width: string
  height: number
}) => {
  return (
    <Container
      isWrap
      width={width}
    >
      <Spacer.Horizontal size={8} />
      <Row
        alignItems='center'
        justifyContent='between'
        width='100%'
      >
        <TextBackBone
          height={15}
          width='150px'
        />
        <Container width='40px'>
          <Backbone
            borderRadius='100%'
            height={height}
          />
        </Container>
      </Row>
    </Container>
  )
}

export const CustomHeader = ({
  breadcrumbData,
  isMobile,
  isSuggestionsPage,
  selectedRadio,
  title,
  totalQuantity,
  hasFacets,
  isLoadingFacets,
  handleOpenFiltersModal
}: CustomHeaderProps) => {
  const scroll = useScrollListener()
  const {
    router,
    primary,
    innerWidth,
    showBackBone,
    fontSizeQuantity,
    titleText,
    searchTerm,
    resultText
  } = useCustomHeader({
    isMobile,
    title,
    totalQuantity,
    isSuggestionsPage,
    isLoadingFacets
  })

  useCustomHeaderScrollBehavior({ isMobile, innerWidth, scroll, primary })

  if (isMobile && !router?.query?.brand) {
    return (
      <Row
        alignItems='center'
        customClassName={styles.customHeader}
        justifyContent='center'
        ref={primary}
      >
        {showBackBone ? (
          <FiltersBarV2MobileBackBone
            height={40}
            width='90%'
          />
        ) : (
          <FiltersBarV2
            handleOpenFiltersModal={handleOpenFiltersModal}
            hasFacets={hasFacets}
            orderByName={selectedRadio}
            totalQuantity={totalQuantity}
          />
        )}
      </Row>
    )
  }

  return (
    <>
      <Row>
        {!isSuggestionsPage && (
          <>
            {!showBackBone ? (
              <Breadcrumbs
                afterClick={(label) => {
                  clickPlp(label, 'breadcrumb')
                }}
                linkWrapper={Link}
                links={breadcrumbData}
              />
            ) : (
              <TextBackBone
                height={10}
                width='200px'
              />
            )}
          </>
        )}
      </Row>
      <Spacer.Horizontal size={12} />
      <Row alignItems='baseline'>
        {!showBackBone ? (
          <Title
            fontWeight={isSuggestionsPage ? 'medium' : 'semibold'}
            headingLevel={isSuggestionsPage ? 'h3' : 'h1'}
            size='small'
          >
            {titleText}
          </Title>
        ) : (
          <TextBackBone
            height={20}
            width='100px'
          />
        )}
        {isSuggestionsPage && (
          <>
            <Spacer.Vertical size={4} />
            <Title
              color={getGlobalStyle('--color-primary-light-red')}
              fontWeight={isSuggestionsPage ? 'medium' : 'semibold'}
              headingLevel={isSuggestionsPage ? 'h3' : 'h1'}
              size='small'
            >
              {searchTerm}
            </Title>
          </>
        )}
        <Spacer.Vertical size={8} />
        {!showBackBone ? (
          <Text
            color='gray'
            fontSize={fontSizeQuantity}
          >
            {resultText}
          </Text>
        ) : (
          <TextBackBone
            height={10}
            width='60px'
          />
        )}
      </Row>
    </>
  )
}
