import {
  cleanRut,
  formatRut,
  sanitizeRut,
  validateRut
} from '@smu-chile/pkg-unimarc-components/utils'
import { FormField, ValidationsType } from 'shared/interfaces/Form'

export const validateError = (
  formField: FormField,
  formFieldValue: unknown
) => {
  const value =
    formField?.validations === ValidationsType.RUT
      ? cleanRut(formFieldValue as string)
      : formFieldValue

  if (formField?.validations === ValidationsType.Email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return !emailRegex.test(value as string)
  }

  if (formField?.validations === ValidationsType.RUT) {
    return !validateRut(value as string)
  }

  if (formField?.minValue) {
    return (value as string)?.length < formField.minValue
  }

  if (formField?.maxValue) {
    return (value as string)?.length > formField.maxValue
  }

  return false
}

export const sanitizeInputValue = (
  value: string,
  formField: FormField
): string => {
  if (!value) return ''

  let sanitizedValue = value

  switch (formField.validations) {
    case ValidationsType.RUT:
      sanitizedValue =
        cleanRut(value).length < 10 ? formatRut(sanitizeRut(value)) : value
      break
    case ValidationsType.OnlyNumbers:
    case ValidationsType.PhoneNumber:
      sanitizedValue = value.replace(/\D/g, '')
      break
    case ValidationsType.OnlyText:
      sanitizedValue = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, '')
      break
    default:
      sanitizedValue = value
  }

  if (
    formField.maxValue &&
    sanitizedValue.length > formField.maxValue &&
    formField.validations !== ValidationsType.RUT
  ) {
    sanitizedValue = sanitizedValue.slice(0, formField.maxValue)
  }

  return sanitizedValue
}
