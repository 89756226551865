import { useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import {
  Column,
  Container,
  InputLabel,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { SignUpInputLabelProps } from './types'
import styles from './SignUpInputLabel.module.css'
import { SignUpLabelTooltip } from '../SignUpLabelTooltip'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

export const SignUpInputLabel = ({
  appendIcon,
  disabled,
  error,
  errorLabel,
  gap = 6,
  infoTooltip,
  label,
  onAppendIconClick,
  onChange,
  onClickIcon,
  inputRef,
  tooltip,
  tooltipMobile,
  tooltipMobileTitle,
  type,
  value,
  max
}: SignUpInputLabelProps) => {
  const inputContainerRef = useRef<HTMLDivElement>()
  const [focused, setFocused] = useState(false)

  const active = useMemo(() => {
    return value && value !== ''
  }, [value])

  const iconColor = useMemo(() => {
    if (error && error !== '') {
      return getGlobalStyle('--color-neutral-gray-input')
    }

    if (active || focused) {
      return getGlobalStyle('--color-neutral-black')
    }

    return getGlobalStyle('--color-neutral-gray-input')
  }, [active, error, focused])

  const handleAppendIconClick = () => {
    if (inputContainerRef.current) {
      const inputs = inputContainerRef.current.getElementsByTagName('input')

      inputs.item(0)?.focus()
    }

    onAppendIconClick()
  }

  const handleInputLabelBlur = () => {
    setFocused(false)
  }

  const handleInputLabelFocus = () => {
    setFocused(true)
  }

  return (
    <Column customClassName={classNames(styles['input-label-wrapper'])}>
      {tooltip && (
        <SignUpLabelTooltip
          iconColor={iconColor}
          tooltipMobile={tooltipMobile}
          tooltipMobileTitle={tooltipMobileTitle}
        >
          {tooltip}
        </SignUpLabelTooltip>
      )}

      <Column gap={gap}>
        <Column ref={inputContainerRef}>
          <InputLabel
            appendIcon={appendIcon}
            appendIconColor={iconColor}
            customClassName={classNames(styles['input-label'], {
              [styles['input-label__active']]: active,
              [styles['input-label__focus']]: focused,
              [styles['input-label__error']]: error,
              [styles['input-date']]: type === 'date'
            })}
            disabled={disabled}
            fontSize='md'
            infoTooltip={infoTooltip}
            inputWidth='100%'
            label={label}
            max={max}
            onAppendIconClick={handleAppendIconClick}
            onBlur={handleInputLabelBlur}
            onChange={onChange}
            onClickIcon={onClickIcon}
            onFocus={handleInputLabelFocus}
            ref={inputRef}
            showXIcon={false}
            type={type}
            value={value}
          />
        </Column>

        {error && error !== '' && (
          <Container padding='0 1rem'>
            <Text
              color='error'
              fontSize='xs'
            >
              {errorLabel || error}
            </Text>
          </Container>
        )}
      </Column>
    </Column>
  )
}
