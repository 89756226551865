export const headerBottomBannerQuery = `query {
  homeCollection(where: { label: "[Unimarc Web] Home" }) {
		items {
			headerBannerApp {
				...on ImageBannerWeb {
					referenceType
		      reference
					imageMobile {
						url
					}
					imageDesktop {
						url
					}
				}
			}
		}
	}
}
`
