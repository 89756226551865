import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import Image from 'next/future/image'
import {
  useProdutsCarousel,
  useShelvesData
} from '../../ProductsCarousel/helpers/useProdutsCarousel'
import {
  useUpdateFromShelf,
  useEvents,
  visiblePromos,
  addToHomeData,
  isValidArrayWithData,
  useProductsSearch,
  useLocalAddress
} from '@smu-chile/pkg-unimarc-hooks'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import {
  Button,
  Column,
  Container,
  Divider,
  Row,
  ShelfCarousel,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { ModalBackBone } from '../ModalBackBone/ModalBackBone'
import { Shelves } from '../../Shelves/index'
import { CarouselError } from './CarouselError'
import classNames from 'classnames'
import { useFavoriteProductsManager } from 'shared/hooks/useFavoriteProductsManager'
import styles from './SimilarsCarousel.module.css'

export interface SimilarsCarrouselProps {
  categoryId: string
  isMobile?: boolean
  customItemToSlide?: number
  customItemToShow?: number
  ean?: string
  handleOpenMyListsModal?: (item: IProductInList) => void
  onClickProduct?: () => void
  setIsOpenSimilarsModal?: (state: boolean) => void
}
export const SimilarsCarouselComponent = ({
  categoryId,
  isMobile,
  customItemToSlide,
  customItemToShow,
  ean,
  handleOpenMyListsModal,
  onClickProduct,
  setIsOpenSimilarsModal
}: SimilarsCarrouselProps) => {
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const { hasLocalAddress } = useLocalAddress()
  const carouselRef = useRef(null)
  const carouselView = useRef(0)
  const isSimilarsModal = true
  const fromPDP = window.location.href.includes('product')
  const desktopCondition = fromPDP ? '' : '16px 0 0 0'
  const mobileCondition = fromPDP ? 42 : 16
  const listId = 'productos_similares'
  const listName = 'PDP - Productos Similares'
  const site = 'Unimarc'
  const productsN3 = useProductsSearch({
    categories: categoryId,
    from: '0',
    to: '9',
    reactQuery: {
      queryKey: categoryId,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const { availableProducts = [] } = productsN3?.data || {}
  const products = availableProducts?.filter((product) => {
    return product['ean'] !== ean
  })

  const { handleToggleFavorite, isProductFavorite, ToastFavoriteUI } =
    useFavoriteProductsManager()

  const { positionContainer, itemsToShow, itemsToSlide, spacerBelow } =
    useProdutsCarousel({ isMobile })

  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading,
    products,
    isProductCarousel: true,
    hasLocalAddress
  })

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  const handlePDPShelf = ({ ref, shelf }) => {
    addToHomeData({
      ref,
      promoData: { ...shelf, promoTitle: '' },
      isShelf: true
    })
  }

  const productsMemo = useShelvesData(shelvesData, isMobile) || []

  const carouselShelfData = {
    positionContainer,
    productsMemo,
    itemsToShow: customItemToShow || itemsToShow,
    itemsToSlide: customItemToSlide || itemsToSlide,
    spacerBelow,
    handleOnClick,
    handleAdd,
    handleRemove,
    handleChange
  }

  const isLoading: boolean = productsN3.isLoading

  const handleGoBack = () => {
    setIsOpenSimilarsModal(false)
  }
  const handleIntersect = (entries) => {
    const [entry] = entries
    setIsIntersecting(entry.isIntersecting)
  }

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, options)
    if (carouselRef.current) observer.observe(carouselRef.current)
    carouselView.current += 1
    return () => {
      if (carouselRef.current && carouselView.current === 1)
        observer.unobserve(carouselRef.current)
    }
  }, [carouselRef, options])

  if (isLoading) {
    return <ModalBackBone isMobile={isMobile} />
  }

  if (productsMemo?.length === 0) {
    return (
      <>
        <Column customHeight='96%'>
          <Row customHeight='100%'>
            <CarouselError
              customImageSize={{ width: '246', height: '369' }}
              hideButton
              modalPLP
            />
          </Row>
        </Column>
        <Row
          backgroundColor={getGlobalStyle('--color-base-white')}
          borderRadius='12px 12px 0 0'
          boxShadow='0px 0px 6px rgba(27, 33, 56, 0.15)'
          customClassName={classNames({
            [styles['styles.containerStickyButtonsMobile']]: isMobile,
            [styles['styles.containerStickyButtons']]: !isMobile
          })}
          justifyContent='center'
          padding='16px 10px'
        >
          <Button
            background={getGlobalStyle('--color-base-black')}
            borderRadius={getGlobalStyle('--border-radius-lg')}
            label='Volver'
            onClick={handleGoBack}
            size='large'
            width={isMobile ? '100%' : '410px'}
          />
        </Row>
      </>
    )
  }

  return (
    <>
      {isValidArrayWithData(products) && (
        <>
          <ToastFavoriteUI isMobile={isMobile} />
          <Row
            isWrap
            width={800}
          >
            <Container
              justifyContent='center'
              padding={isMobile ? '0 20px 0 10px' : desktopCondition}
            >
              <Title
                color={getGlobalStyle('--color-text-black')}
                customClassName={isMobile ? styles.titleMobile : ''}
                customFontSize='16px'
                fontWeight={isMobile ? 'medium' : 'semibold'}
                headingLevel='h4'
                text='Similares a tu producto'
                textAlign='center'
              />
            </Container>
            <Divider
              borderHeight='xs'
              color={getGlobalStyle('--color-neutral-gray-divider')}
              verticalMargin={isMobile ? mobileCondition : 16}
            />
            <Row
              justifyContent='center'
              margin='0 0 12px'
              maxWidth='1120px'
            >
              <Row
                padding='10px'
                ref={carouselRef}
              >
                {isIntersecting &&
                  visiblePromos({
                    site: 'Unimarc',
                    listIdVal: 'productos_similares',
                    listNameVal: 'PDP - Productos Similares'
                  })}

                {!isMobile && (
                  <Row padding={isMobile ? '12px' : 'none'}>
                    <ShelfCarousel
                      carouselProps={{
                        infinite: true,
                        autoPlay: false,
                        shouldResetAutoplay: false,
                        afterChange: () => {
                          return visiblePromos({
                            site,
                            listIdVal: listId,
                            listNameVal: listName
                          })
                        },
                        partialVisible: true
                      }}
                      catchShelfRef={handlePDPShelf}
                      containerProps={carouselShelfData.positionContainer}
                      handleOnClick={carouselShelfData.handleOnClick}
                      handleOpenMyListsModal={handleOpenMyListsModal}
                      isMobile={isMobile}
                      isProductFavorite={isProductFavorite}
                      isSimilarsModal={isSimilarsModal}
                      items={carouselShelfData.productsMemo}
                      itemsToShow={carouselShelfData.itemsToShow}
                      linkWrapper={Link}
                      nextImage={Image}
                      onClickProduct={onClickProduct}
                      onToggleFavorite={handleToggleFavorite}
                      quantityButtonProps={{
                        handleAdd: carouselShelfData.handleAdd,
                        handleRemove: carouselShelfData.handleRemove,
                        handleChange: carouselShelfData.handleChange
                      }}
                      quantityButtonStyles={{
                        fontSize: 'md',
                        margin: '0px',
                        maxHeight: '30px',
                        maxWidth: '163px',
                        sizeCircle: 22
                      }}
                      showAddToFavoriteButton={true}
                      showAddToListButton={true}
                      showArrows={!isMobile}
                      showCouponButton={false}
                      slidesToSlide={carouselShelfData.itemsToSlide}
                    />
                  </Row>
                )}
                {isMobile && (
                  <Shelves
                    isMobile={isMobile}
                    isSimilarsModal={isSimilarsModal}
                    products={carouselShelfData.productsMemo}
                  />
                )}
              </Row>
            </Row>
          </Row>
        </>
      )}
    </>
  )
}

function handleCarousel(prevState, nextState) {
  return prevState.clusterData === nextState.clusterData
}
export const SimilarsCarousel = React.memo(
  SimilarsCarouselComponent,
  handleCarousel
)
