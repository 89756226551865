import {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useRouter } from 'next/router'
import {
  Button,
  ButtonStatus,
  Column,
  Container,
  Link,
  Row,
  Text,
  ValidationText
} from '@smu-chile/pkg-unimarc-components'
import { formatRut, validateRut } from '@smu-chile/pkg-unimarc-components/utils'
import {
  datalayerSimpleEvent,
  setClientDocumentCookie,
  useAuthRegister,
  useAuthRegisterConfirm,
  useLocalStorage,
  useMobile,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import { ConfirmCodeModal } from 'components/ConfirmCodeModal'
import { validateEmptyString, validatePatternString } from 'shared/utils'
import { SignUpInputLabel } from '../SignUpInputLabel'
import { SignUpInfoModal } from '../SignUpInfoModal'
import { SignUpToast } from '../SignUpToast'
import {
  emailPattern,
  getEmailErrorMessage,
  getErrorMessage,
  getPhoneErrorMessage,
  getRutErrorMessage,
  getValidationTextColor,
  lastnamePattern,
  lengthPattern,
  lowerCasePattern,
  namePattern,
  numberPattern,
  phonePattern,
  rutPattern,
  specialCharacterPattern,
  upperCasePattern
} from './helpers'
import { SignUpData, SignUpFormProps } from './types'
import styles from './SignUpForm.module.css'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { SignUpSwitch } from '../SignUpSwitch'
import { cleanName } from './helpers/cleanName'
import { getErrorRetryAfter } from './helpers/getErrorRetryAfter'
import { useRetryAfter } from './helpers/useRetryAfter'
import { getErrorRetryAfterMessage } from './helpers/getErrorRetryAfterMessage'
import { cleanNumber } from './helpers/cleanNumber'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export const SignUpForm = ({
  clientDocumentCookie,
  logoImage,
  ppRichTextData,
  tycRichTextData
}: SignUpFormProps) => {
  const router = useRouter()
  const mobileData = useMobile()
  const [isConfirming, setIsConfirming] = useState<boolean>(false)

  const [isOTPModalOpen, setIsOTPModalOpen] = useState<boolean>(false)
  const [isPPModalOpen, setIsPPModalOpen] = useState<boolean>(false)
  const [isTYCModalOpen, setIsTYCModalOpen] = useState<boolean>(false)
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false)

  const [defaultEmailErrorMessage, setDefaultEmailErrorMessage] =
    useState<string>(null)
  const [defaultPhoneErrorMessage, setDefaultPhoneErrorMessage] =
    useState<string>(null)
  const [defaultRutErrorMessage, setDefaultRutErrorMessage] =
    useState<string>(null)

  const inputPasswordRef = useRef<HTMLInputElement>(null)

  const [errorMessage, setErrorMessage] = useState<string>(null)
  const [errorTooltipMessage, setErrorTooltipMessage] = useState<string>(null)

  const [infoToastMessage, setInfoToastMessage] = useState<string>(null)

  const [otpUnblocked, setOTPUnblocked] = useState(true)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_showWelcome, setShowWelcome] = useLocalStorage(
    'SSO/showWelcome',
    'false'
  )

  const { retryAfter, setRetryAfter } = useRetryAfter({
    increase: -1
  })

  const [signUpData, setSignUpData] = useState<SignUpData>({
    acceptTYCandPP: false,
    destination: '',
    email: '',
    lastname: '',
    birthdate: '',
    name: '',
    otp: '',
    password: '',
    phone: '',
    pp: '',
    rut: '',
    tyc: ''
  })

  const { isFetching: isSigningUp, refetch: sendAuthRegister } =
    useAuthRegister({
      email: signUpData?.email,
      familyName: signUpData?.lastname,
      name: signUpData?.name,
      password: signUpData?.password,
      phone: `+569${signUpData?.phone}`,
      rut: signUpData?.rut,
      birthdate: signUpData?.birthdate
    })

  const queryClient = useQueryClient()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { refetch: sendAuthRegisterConfirm } = useAuthRegisterConfirm({
    code: signUpData?.otp,
    rut: signUpData?.rut
  })

  const [inputEmailEmpty, inputEmailValid] = useMemo(() => {
    return [
      validateEmptyString(signUpData?.email),
      validatePatternString(emailPattern, signUpData?.email)
    ]
  }, [signUpData?.email])

  const [inputNameEmpty, inputNameValid] = useMemo(() => {
    return [
      validateEmptyString(signUpData?.name),
      validatePatternString(namePattern, signUpData?.name)
    ]
  }, [signUpData?.name])

  const [inputLastnameEmpty, inputLastnameValid] = useMemo(() => {
    return [
      validateEmptyString(signUpData?.lastname),
      validatePatternString(lastnamePattern, signUpData?.lastname)
    ]
  }, [signUpData?.lastname])

  const [inputRutEmpty, inputRutValid] = useMemo(() => {
    return [
      validateEmptyString(signUpData?.rut),
      validatePatternString(rutPattern, signUpData?.rut) &&
        validateRut(signUpData?.rut)
    ]
  }, [signUpData?.rut])

  const [inputPhoneEmpty, inputPhoneValid] = useMemo(() => {
    return [
      validateEmptyString(signUpData?.phone),
      validatePatternString(phonePattern, signUpData?.phone)
    ]
  }, [signUpData?.phone])

  const [
    inputPasswordEmpty,
    inputPasswordLowerCaseValid,
    inputPasswordMinLengthValid,
    inputPasswordNumberValid,
    inputPasswordSpecialCharacterValid,
    inputPasswordUpperCaseValid
  ] = useMemo(() => {
    return [
      validateEmptyString(signUpData?.password),
      validatePatternString(lowerCasePattern, signUpData?.password),
      validatePatternString(lengthPattern, signUpData?.password),
      validatePatternString(numberPattern, signUpData?.password),
      validatePatternString(specialCharacterPattern, signUpData?.password),
      validatePatternString(upperCasePattern, signUpData?.password)
    ]
  }, [signUpData?.password])

  const buttonStatus = useMemo<ButtonStatus>(() => {
    if (isSigningUp) {
      return 'loading'
    }

    if (
      inputEmailValid &&
      inputLastnameValid &&
      inputNameValid &&
      inputPasswordLowerCaseValid &&
      inputPasswordMinLengthValid &&
      inputPasswordNumberValid &&
      inputPasswordSpecialCharacterValid &&
      inputPasswordUpperCaseValid &&
      inputPhoneValid &&
      inputRutValid &&
      otpUnblocked &&
      signUpData?.acceptTYCandPP
    ) {
      return 'initial'
    }

    return 'disabled'
  }, [
    inputEmailValid,
    inputLastnameValid,
    inputNameValid,
    inputPasswordLowerCaseValid,
    inputPasswordMinLengthValid,
    inputPasswordNumberValid,
    inputPasswordSpecialCharacterValid,
    inputPasswordUpperCaseValid,
    inputPhoneValid,
    inputRutValid,
    isSigningUp,
    otpUnblocked,
    signUpData?.acceptTYCandPP
  ])

  const handleButtonClick = async () => {
    const token = await executeRecaptcha('identity_lookup')
    queryClient.setQueryData('authRegisterUnimarc', token)
    sendAuthRegister().then(({ data }) => {
      if (data?.error) {
        setDefaultEmailErrorMessage(getEmailErrorMessage(data.data))
        setDefaultPhoneErrorMessage(getPhoneErrorMessage(data.data))
        setDefaultRutErrorMessage(getRutErrorMessage(data.data))
        setErrorMessage(getErrorMessage(data.data))
      } else {
        setSignUpData({
          ...signUpData,
          destination: data?.data?.codeDelivery?.destination
        })
        setIsOTPModalOpen(true)
      }
    })
  }

  const handleButtonFakeOTPClick = () => {
    setErrorMessage(
      `Podrás intentar tu registro nuevamente en ${getErrorRetryAfterMessage(
        retryAfter
      )}.`
    )
  }

  const handleInfoToastClick = () => {
    setInfoToastMessage(null)
  }

  const handleInputLabelChange = (target: string) => {
    return (event?: ChangeEvent<HTMLInputElement>) => {
      setDefaultEmailErrorMessage(null)
      setDefaultPhoneErrorMessage(null)
      setDefaultRutErrorMessage(null)
      setErrorMessage(null)
      setOTPUnblocked(true)

      const { value } = event.target

      if (target === 'name' || target === 'lastname') {
        setSignUpData({
          ...signUpData,
          [target]: cleanName(value)
        })
      } else if (target === 'phone') {
        setSignUpData({
          ...signUpData,
          [target]: value.length > 8 ? signUpData.phone : cleanNumber(value)
        })
      } else if (target === 'rut') {
        setSignUpData({
          ...signUpData,
          [target]: value.length > 12 ? signUpData.rut : value
        })

        setRetryAfter(0)
      } else {
        setSignUpData({
          ...signUpData,
          [target]: value
        })
      }
    }
  }

  const handleInputLabelClickIcon = (target: string) => {
    return (event?: MouseEvent<HTMLElement>) => {
      if (event) {
        setSignUpData({
          ...signUpData,
          [target]: ''
        })
      }
    }
  }

  const handleInputPasswordIconClick = () => {
    setIsPasswordShow(!isPasswordShow)

    if (inputPasswordRef.current) {
      inputPasswordRef.current.focus()
    }
  }

  const handleOTPModalChange = (value?: string) => {
    setSignUpData({
      ...signUpData,
      otp: value
    })
    setErrorTooltipMessage(null)
  }

  const handleOTPModalClose = () => {
    setIsOTPModalOpen(false)
  }

  const handleOTPModalResendSubmit = async () => {
    sendAuthRegister().then(({ data }) => {
      if (data.error) {
        setDefaultEmailErrorMessage(getEmailErrorMessage(data.data))
        setDefaultPhoneErrorMessage(getPhoneErrorMessage(data.data))
        setDefaultRutErrorMessage(getRutErrorMessage(data.data))
        setErrorTooltipMessage(getErrorMessage(data.data))
      } else {
        setInfoToastMessage('Se te ha enviado un nuevo código de verificación.')
      }
    })
  }

  const handleOTPModalTooltipClick = () => {
    setErrorTooltipMessage(null)
  }

  const handlePPModalClose = () => {
    setIsPPModalOpen(false)
  }

  const handlePPModalSubmit = () => {
    setSignUpData({
      ...signUpData,
      pp: 'true'
    })
    setIsPPModalOpen(false)
  }

  const handleTYCModalClose = () => {
    setIsTYCModalOpen(false)
  }

  const handleTYCModalSubmit = () => {
    setSignUpData({
      ...signUpData,
      tyc: 'true'
    })
    setIsTYCModalOpen(false)
  }

  const handleSwitchTYCClick = () => {
    setSignUpData({
      ...signUpData,
      acceptTYCandPP: !signUpData?.acceptTYCandPP
    })
  }

  useEffect(() => {
    if (signUpData?.otp?.length > 5) {
      setIsConfirming(true)

      sendAuthRegisterConfirm().then(async ({ data }) => {
        if (data.error) {
          const retryAfter = getErrorRetryAfter(data.data)
          setErrorTooltipMessage(getErrorMessage(data.data))
          setRetryAfter(retryAfter)
          setIsConfirming(false)
        } else {
          // clean clientDocument cookie
          await setClientDocumentCookie(undefined)
          router.push('/')
          setIsConfirming(false)
          setShowWelcome('true')
        }
      })
    }
  }, [signUpData?.otp])

  useEffect(() => {
    setOTPUnblocked(retryAfter === 0)
  }, [retryAfter])

  useEffect(() => {
    if (isOTPModalOpen) {
      datalayerSimpleEvent({
        event: 'register',
        eventAction: 'view',
        eventCategory: 'code',
        eventLabel: 'enter_code'
      })
    }
  }, [isOTPModalOpen])

  useEffect(() => {
    const rut = clientDocumentCookie || ''
    if (rut) {
      setSignUpData({
        ...signUpData,
        rut: rut.length > 12 ? '' : rut
      })
    }
  }, [router])

  return (
    <Column maxWidth='31rem'>
      <Column
        gap={24}
        hidden={isOTPModalOpen && mobileData.innerWidth < 1280}
        maxWidth='31rem'
        padding='1.25rem 1rem'
        position='relative'
      >
        <Column gap={16}>
          <Text fontWeight='semibold'>Datos personales</Text>

          <Column gap={24}>
            <SignUpInputLabel
              disabled={isSigningUp}
              error={!inputNameValid && !inputNameEmpty}
              errorLabel='Ingrese un nombre válido.'
              label='Nombre'
              onChange={handleInputLabelChange('name')}
              onClickIcon={handleInputLabelClickIcon('name')}
              value={signUpData?.name}
            />

            <SignUpInputLabel
              disabled={isSigningUp}
              error={!inputLastnameValid && !inputLastnameEmpty}
              errorLabel='Ingrese un apellido válido.'
              label='Apellido'
              onChange={handleInputLabelChange('lastname')}
              onClickIcon={handleInputLabelClickIcon('lastname')}
              value={signUpData?.lastname}
            />

            <SignUpInputLabel
              disabled={isSigningUp}
              error={
                inputRutValid || inputRutEmpty
                  ? defaultRutErrorMessage
                  : 'Ingrese un RUT válido.'
              }
              label='RUT'
              onChange={handleInputLabelChange('rut')}
              onClickIcon={handleInputLabelClickIcon('rut')}
              tooltip={
                <Column gap={4}>
                  <Text
                    color='white'
                    fontSize='md'
                  >
                    ¿Por qué te pedimos el RUT?
                  </Text>
                  <Text
                    color='white'
                    fontSize='sm'
                  >
                    Necesitamos tu RUT para validar tu identidad al momento de
                    generar transacciones. No te preocupes, tus datos estarán
                    protegidos con nosotros.
                  </Text>
                </Column>
              }
              tooltipMobile={
                <>
                  <Column padding='1rem'>
                    <Text
                      fontSize='md'
                      lineHeight='15px'
                    >
                      Necesitamos tu RUT para validar tu identidad al momento de
                      generar transacciones. No te preocupes, tus datos estarán
                      protegidos con nosotros.
                    </Text>
                  </Column>
                </>
              }
              tooltipMobileTitle='¿Por qué te pedimos el RUT?'
              value={formatRut(signUpData?.rut)}
            />

            <SignUpInputLabel
              disabled={isSigningUp}
              errorLabel='Ingrese una fecha de nacimiento valida.'
              label='Fecha de cumpleaños'
              max={new Date().toISOString().split('T')[0]}
              onChange={handleInputLabelChange('birthdate')}
              onClickIcon={handleInputLabelClickIcon('birthdate')}
              type='date'
              value={signUpData?.birthdate}
            />
          </Column>
        </Column>

        <Container
          margin='0 auto'
          overflow='hidden'
        >
          <svg
            fill='none'
            height='1'
            viewBox='0 0 500 1'
            width='500'
            xmlns='http://www.w3.org/2000/svg'
          >
            <line
              stroke='#dadada'
              strokeDasharray='1 4'
              strokeLinecap='round'
              x1='0.5'
              x2='500.5'
              y1='0.5'
              y2='0.5'
            />
          </svg>
        </Container>

        <Column gap={16}>
          <Text fontWeight='semibold'>Datos de contacto</Text>

          <Column gap={24}>
            <SignUpInputLabel
              disabled={isSigningUp}
              error={
                inputEmailValid || inputEmailEmpty
                  ? defaultEmailErrorMessage
                  : 'Ingrese un correo electrónico válido.'
              }
              label='Correo electrónico'
              onChange={handleInputLabelChange('email')}
              onClickIcon={handleInputLabelClickIcon('email')}
              value={signUpData?.email}
            />

            <Row gap={8}>
              <Container
                alignItems='center'
                backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
                border={`${getGlobalStyle(
                  '--border-width-2xs'
                )} solid ${getGlobalStyle('--color-neutral-gray-input')}`}
                borderRadius='.75rem'
                maxWidth='5.628rem'
                minHeight='2.5rem'
                padding='0 1rem'
              >
                <Text
                  customColor='var(--color-neutral-gray-input)'
                  fontSize='md'
                >
                  +569
                </Text>
              </Container>

              <SignUpInputLabel
                disabled={isSigningUp}
                error={
                  inputPhoneValid || inputPhoneEmpty
                    ? defaultPhoneErrorMessage
                    : 'Ingrese un teléfono válido.'
                }
                label='Numero de teléfono'
                onChange={handleInputLabelChange('phone')}
                onClickIcon={handleInputLabelClickIcon('phone')}
                value={signUpData?.phone}
              />
            </Row>
          </Column>
        </Column>

        <Container
          margin='0 auto'
          overflow='hidden'
        >
          <svg
            fill='none'
            height='1'
            viewBox='0 0 500 1'
            width='500'
            xmlns='http://www.w3.org/2000/svg'
          >
            <line
              stroke='#dadada'
              strokeDasharray='1 4'
              strokeLinecap='round'
              x1='0.5'
              x2='500.5'
              y1='0.5'
              y2='0.5'
            />
          </svg>
        </Container>

        <Column gap={16}>
          <Text fontWeight='semibold'>Crear contraseña</Text>

          <SignUpInputLabel
            appendIcon={isPasswordShow ? 'EyeThin' : 'EyeSlashThin'}
            disabled={isSigningUp}
            inputRef={inputPasswordRef}
            label='Contraseña'
            onAppendIconClick={handleInputPasswordIconClick}
            onChange={handleInputLabelChange('password')}
            onClickIcon={handleInputLabelClickIcon('password')}
            type={isPasswordShow ? 'text' : 'password'}
            value={signUpData?.password}
          />

          <Column
            backgroundColor='var(--global-neutral-solid-colors-90, #F9F9F9)'
            borderRadius='8px'
            gap={8}
            padding='16px'
          >
            <Text
              fontSize='sm'
              fontWeight='semibold'
            >
              Debes usar al menos:
            </Text>

            <ValidationText
              color={getValidationTextColor(
                inputPasswordEmpty ? null : inputPasswordMinLengthValid
              )}
              fontSize='sm'
              gap={8}
              iconName='Dot'
              iconSize={8}
              text='Mínimo 8 carácteres'
            />

            <ValidationText
              color={getValidationTextColor(
                inputPasswordEmpty ? null : inputPasswordLowerCaseValid
              )}
              fontSize='sm'
              gap={8}
              iconName='Dot'
              iconSize={8}
              text='1 minúscula (a-z)'
            />

            <ValidationText
              color={getValidationTextColor(
                inputPasswordEmpty ? null : inputPasswordNumberValid
              )}
              fontSize='sm'
              gap={8}
              iconName='Dot'
              iconSize={8}
              text='1 número (0-9)'
            />

            <ValidationText
              color={getValidationTextColor(
                inputPasswordEmpty ? null : inputPasswordSpecialCharacterValid
              )}
              fontSize='sm'
              gap={8}
              iconName='Dot'
              iconSize={8}
              text='1 carácter especial (Ej: *·#%)'
            />

            <ValidationText
              color={getValidationTextColor(
                inputPasswordEmpty ? null : inputPasswordUpperCaseValid
              )}
              fontSize='sm'
              gap={8}
              iconName='Dot'
              iconSize={8}
              text='1 mayúscula (A-Z)'
            />
          </Column>

          <Row
            alignItems='center'
            gap={12}
            height={28}
          >
            <Container
              height={28}
              width={52}
            >
              <SignUpSwitch
                onClick={handleSwitchTYCClick}
                value={signUpData?.acceptTYCandPP}
              />
            </Container>

            <Text
              display='block'
              fontSize='sm'
            >
              Soy mayor de 18 años y acepto los&nbsp;
              <Link
                color={getGlobalStyle('--color-neutral-black')}
                customClassName={styles.tycLink}
                fontSize='sm'
                fontWeight='semibold'
                href=''
                onClick={() => {
                  return setIsTYCModalOpen(true)
                }}
                underline='hover'
              >
                términos y condiciones
              </Link>
              &nbsp;y&nbsp;
              <Link
                color={getGlobalStyle('--color-neutral-black')}
                fontSize='sm'
                fontWeight='semibold'
                href=''
                onClick={() => {
                  return setIsPPModalOpen(true)
                }}
                underline='hover'
              >
                políticas de privacidad
              </Link>
            </Text>
          </Row>
        </Column>

        <Column gap={16}>
          <Column>
            {!otpUnblocked && (
              <Container position='relative'>
                <Container
                  backgroundColor='transparent'
                  clickable='pointer'
                  minHeight='40px'
                  onClick={handleButtonFakeOTPClick}
                  position='absolute'
                  width='100%'
                />
              </Container>
            )}

            <Button
              borderRadius='1.5rem'
              fontWeight='normal'
              fullWidth
              isLoading={isSigningUp}
              label='Registrarme'
              onClick={handleButtonClick}
              status={buttonStatus}
            />
          </Column>
        </Column>

        <SignUpInfoModal
          buttonLabel='Entendido'
          isOpen={isPPModalOpen}
          onClose={handlePPModalClose}
          onSubmit={handlePPModalSubmit}
          richTextData={ppRichTextData}
          title='Políticas de privacidad'
        />

        <SignUpInfoModal
          buttonLabel='Entendido'
          isOpen={isTYCModalOpen}
          onClose={handleTYCModalClose}
          onSubmit={handleTYCModalSubmit}
          richTextData={tycRichTextData}
          title='Términos y condiciones'
        />

        {errorMessage && (
          <SignUpToast
            message={errorMessage}
            onClick={() => {
              setErrorMessage(null)
            }}
            positionContainer='fixed'
            top='64px'
          />
        )}
      </Column>

      {isOTPModalOpen && (
        <ConfirmCodeModal
          errorMessage={errorTooltipMessage}
          infoMessage={infoToastMessage}
          isConfirming={isConfirming}
          isOpen={isOTPModalOpen}
          logoImage={logoImage}
          onChange={handleOTPModalChange}
          onClose={handleOTPModalClose}
          onInfoToastClick={handleInfoToastClick}
          onResendSubmit={handleOTPModalResendSubmit}
          onTooltipClick={handleOTPModalTooltipClick}
          safeValue={signUpData?.email}
        />
      )}
    </Column>
  )
}
