import { ModalAddressValidationSla } from '@smu-chile/pkg-unimarc-components'
import {
  useMobile,
  useContentful,
  trigger,
  IPostValidateSC,
  updateSalesChannel
} from '@smu-chile/pkg-unimarc-hooks'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { VALIDATE_SC_MODAL_IMAGE } from 'shared/utils/constanst'

interface ValidateSlaWrapperProps {
  openModalSla: boolean
  orderFormData: Data
  validateSlaResponse: IPostValidateSC
  setOpenModalSla: (openModalSla: boolean) => void
}

export const ValidateSlaWrapper = ({
  openModalSla,
  orderFormData,
  validateSlaResponse,
  setOpenModalSla
}: ValidateSlaWrapperProps) => {
  const { isMobile } = useMobile()
  const validateSCModal = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-modal-validate-sc',
    options: {
      'sys.id': VALIDATE_SC_MODAL_IMAGE[0]
    },
    type: 'assets'
  })

  const validateSCModalImage =
    validateSCModal.data?.['items']?.[0]?.fields?.file?.url || ''

  const selectedAddress = orderFormData?.selectedAddresses
  const salesChannel = validateSlaResponse?.sc

  const handleHoldAddress = () => {
    trigger({
      eventType: 'storeLocator',
      data: { show: true, saveSalesChannel: true }
    })
    setOpenModalSla(false)
  }

  const handleContinue = async () => {
    updateSalesChannel(salesChannel)
    setOpenModalSla(false)
  }

  return (
    <>
      {openModalSla && (
        <ModalAddressValidationSla
          {...selectedAddress}
          handleContinue={handleContinue}
          handleHoldAddress={handleHoldAddress}
          isMobile={isMobile}
          openModalSla={openModalSla}
          setOpenModalSla={setOpenModalSla}
          validateSCModalImage={validateSCModalImage}
        />
      )}
    </>
  )
}
