import { useEffect, useRef, useState } from 'react'
import { Button, Picture, Row, Title } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { BaseContainer } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { Form as FormContent } from 'shared/interfaces/Form'
import { FormSection } from './FormSection'
import { validateError } from './helpers/helpers'
import Image from 'next/future/image'
import { postContactabilityForm } from '@smu-chile/pkg-unimarc-hooks'
import { cleanRut } from '@smu-chile/pkg-unimarc-components/utils'
import { ToastWrapper } from 'components/ToastWrapper'
import { IContactabilityFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IContactabilityForm'

interface FormProps {
  isMobile: boolean
  form: FormContent
}

export const Form = ({ isMobile, form }: FormProps) => {
  const [formData, setFormData] = useState<{ [key: string]: [key: string] }>({})
  const [formSuccess, setFormSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [initialFormHeight, setInitialFormHeight] = useState<number | null>(
    null
  )

  const formRef = useRef<HTMLDivElement | null>(null)

  const titleColor = form?.titleColor ?? getGlobalStyle('--color-base-black')
  const labelsColor = form?.labelsColor ?? getGlobalStyle('--color-base-black')
  const backgroundColor =
    form?.backgroundColor ?? getGlobalStyle('--color-base-white')

  const handleChange = (sectionId: string, fieldId: string, value: unknown) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [sectionId]: {
          ...prevData[sectionId],
          [fieldId]: value
        }
      }
    })
  }

  const isFormValid = () => {
    return form.formSections.every((section) => {
      return section.formRows.every((formRow) => {
        return formRow.formFields.every((field) => {
          return (
            !field.isRequired ||
            (formData[section.sectionId] &&
              formData[section.sectionId][field.formfieldId] &&
              !validateError(
                field,
                formData[section.sectionId][field.formfieldId]
              ))
          )
        })
      })
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const formData = buildFormData()

    if (formData?.campaignId === 'formularioConcurso') {
      await submitContactibilityForm({ formData })
    }

    setIsLoading(false)
  }

  const submitContactibilityForm = async (
    formData: IContactabilityFormProps
  ) => {
    try {
      const response = await postContactabilityForm(formData)
      if (response.status === 201) {
        setFormSuccess(true)
      } else {
        setShowToast(true)
      }
    } catch (error) {
      setShowToast(true)
    }
  }

  const buildFormData = () => {
    if (form.formId.includes('formularioConcurso')) {
      const initialData = formData?.userData
      return {
        name: `${initialData?.['name']} ${initialData?.['lastName']}`,
        campaignId: 'formularioConcurso',
        rut: cleanRut(initialData?.['rut']),
        phone: '+569' + initialData?.['phone'],
        email: initialData?.['email'],
        baseContest: initialData?.['baseContest'],
        tyc: initialData?.['tyc']
      }
    }

    return null
  }

  const showSuccessImage = (): boolean => {
    return !!(formSuccess && form.successImage?.fields?.file?.url)
  }

  const showAsideimage = (): boolean => {
    return !!(form.asideImage?.fields?.file?.url && !isMobile)
  }

  const handleCloseToast = () => {
    setShowToast(false)
  }

  useEffect(() => {
    if (formRef.current) {
      setInitialFormHeight(formRef.current.getBoundingClientRect().height)
    }
  }, [])

  return (
    <>
      {showToast && (
        <ToastWrapper
          isSuccessStyle={true}
          message='No pudimos ingresar los datos. Intenta de nuevo.'
          onClose={handleCloseToast}
          success={false}
        />
      )}
      <Row
        alignSelf='center'
        justifyContent='center'
        margin='32px 0px'
        width='100%'
      >
        {showSuccessImage() && (
          <BaseContainer padding={`${isMobile && '0px 16px '}`}>
            <Picture
              height='auto'
              id={getBemId(form.formId, 'success-image')}
              nextImage={Image}
              src={form.successImage?.fields?.file?.url}
              width='auto'
            />
          </BaseContainer>
        )}
        {!formSuccess && (
          <>
            {showAsideimage() && (
              <BaseContainer
                minHeight={`${initialFormHeight}px`}
                width='auto'
              >
                <Picture
                  height='auto'
                  id={getBemId(form.formId, 'aside-image')}
                  nextImage={Image}
                  src={form.asideImage?.fields?.file?.url}
                  width='auto'
                />
              </BaseContainer>
            )}
            <BaseContainer
              flexDirection='column'
              margin={`${!isMobile && '0px 0px 0px 25px'}`}
              maxWidth='618px'
              padding={isMobile && '0px 16px '}
              ref={formRef}
            >
              {form?.title && (
                <Title
                  color={titleColor}
                  customFontSize={getGlobalStyle(
                    `--font-size-${isMobile ? 'xl' : '5xl'}`
                  )}
                  fontWeight='semibold'
                  headingLevel='h3'
                  text={form.title}
                  textAlign='center'
                ></Title>
              )}
              <BaseContainer
                backgroundColor={backgroundColor}
                border={`0.3px solid ${getGlobalStyle(
                  '--color-neutral-gray-divider'
                )}`}
                borderRadius={`${isMobile ? '16px' : '8px'}`}
                flexDirection='column'
                margin='25px 0px 0px 0px'
                padding='22px 16px'
              >
                {form.formSections.map((formSection) => {
                  return (
                    <FormSection
                      formData={formData}
                      formSection={formSection}
                      handleInputChange={handleChange}
                      key={`${form.formId}-${formSection.sectionId}`}
                      labelsColor={labelsColor}
                    ></FormSection>
                  )
                })}
                <Button
                  background={
                    isLoading ? getGlobalStyle('--color-base-black') : null
                  }
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  fontWeight={getGlobalStyle('--font-weight-normal')}
                  id={getBemId('form', 'actionButton')}
                  isLoading={isLoading}
                  label={form?.actionButton?.labelNewText}
                  margin='8px 0px'
                  minWidth={isMobile ? '100%' : '60%'}
                  onClick={handleSubmit}
                  padding='0'
                  size='xl'
                  status={isFormValid() ? 'initial' : 'disabled'}
                  type='primary'
                />
              </BaseContainer>
            </BaseContainer>
          </>
        )}
      </Row>
    </>
  )
}
