import React, { ReactNode, useEffect } from 'react'
import Link from 'next/link'
import { HeaderV3, IconNames, Overlay } from '@smu-chile/pkg-unimarc-components'
import {
  checkUserLoginFlow,
  datalayerSimpleEvent,
  trigger,
  useCache,
  useMobile,
  useOrderForm,
  useSession
} from '@smu-chile/pkg-unimarc-hooks'
import { SearchHeaderWrapper } from './components/SearchHeaderWrapper'
import { useHeaderContentful } from 'shared/utils/hooks'
import { MainHeaderProps } from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderV3/components/MainHeader'
import { CategoriesWrapper } from './components/CategoriesWrapper'
import { BannerBottom } from 'components/HeaderBrowse/BannerBottom'
import { useRouter } from 'next/router'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'
import { handleUserName } from 'components/HeaderBrowse/helpers'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { SSOWelcomeModal } from 'components/SSOLayout/WelcomeModal'
import { HeaderClubBenefitsModal } from 'components/HeaderBrowse/components/HeaderClubBenefitsModal'
import { HeaderClubSavingModal } from 'components/HeaderBrowse/components/HeaderClubSavingModal'
import { handleHeaderSideButtons } from './helpers/handleHeaderSideButtons'
import { useHeaderWrapper } from './hooks/useHeaderWrapper'
import styles from './HeaderWrapper.module.css'

export const HeaderContext = React.createContext({})
const USER_NAME_CACHE_KEY = 'username'

interface HeaderWrapperProps {
  children?: ReactNode
  cartData?: ICart
}

export const HeaderWrapper = ({ children, cartData }: HeaderWrapperProps) => {
  const router = useRouter()
  const showBanner = router.pathname === '/'
  const { isMobileV2: isMobile } = useMobile()
  const { isLoggedIn } = useSession()
  const { data: contenfulData } = useHeaderContentful()
  const { data: orderForm, isLoading: isLoadingOrderForm } = useOrderForm()
  const { isVisible, setIsVisible, useUpdateProps, isOrderFormLoading, total } =
    useHeaderWrapper({ cartData })

  const userName = handleUserName(
    isLoggedIn,
    orderForm?.data?.clientProfileData?.firstName,
    orderForm?.data?.clientProfileData?.lastName
  )

  const userFirstNameCached = useCache(
    USER_NAME_CACHE_KEY,
    userName?.split(' ')[0]
  )

  const handleClickLogin = () => {
    trigger({
      eventType: 'authWrapperOpen',
      data: { isOpen: true }
    })
  }

  const handleClose = () => {
    setIsVisible(false)
  }

  const handleClickCategories = () => {
    trigger({
      eventType: 'categoriesWrapperOpen'
    })
  }

  const handleClickCart = () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: 'carrito'
    })
    const validation = checkUserLoginFlow(
      isLoggedIn,
      orderForm?.data?.data?.selectedAddresses
    )

    if (validation) {
      router.push(process.env.NEXT_PUBLIC_CARTURL)
    } else {
      handleClickLogin()
    }
  }

  const { sideButtons, userMenuButtons } = handleHeaderSideButtons({
    contenfulData,
    isLoggedIn: isLoadingOrderForm ? false : isLoggedIn,
    onClickLogin: handleClickLogin,
    username: isLoadingOrderForm ? null : userFirstNameCached.value,
    isMobile
  })

  const headerProps: MainHeaderProps = {
    backgroundColor: contenfulData?.backgroundColorPrimary,
    colorLogoHeader: contenfulData?.colorLogoHeader,
    customHoverColor: contenfulData?.hoversHeaderButtons,
    customUserMenuHover: contenfulData?.customUserMenuHover,
    isMobile,
    isLoggedIn,
    logo: isMobile
      ? ('UnimarcAndLegendSquare' as IconNames)
      : ('LogoHeaderUnimarcCL' as IconNames),
    onClickLogin: handleClickLogin,
    onClickCategories: handleClickCategories,
    userMenuButtons,
    sideButtons,
    isScrollLocked: isVisible,
    linkWrapper: Link,
    logoUrl: '/',
    cartProps: {
      backgroundQuantity: contenfulData?.colorBackgroundNumberCartIcon,
      colorIcon: contenfulData?.colorTextHeaderPrincipal,
      colorPrice: contenfulData?.colorTextHeaderPrincipal,
      colorQuantity: contenfulData?.colorNumberCartIcon,
      customSizesIcon: 24,
      customSizesQuantityContainer: '18px',
      isMobile: isMobile,
      quantity: useUpdateProps.totalQuantity,
      quantityLoading: isOrderFormLoading,
      site: 'unimarc',
      total: total,
      onClickCart: handleClickCart
    }
  }

  useEffect(() => {
    isVisible
      ? document.body.classList.add(styles['header--body__overflow'])
      : document.body.classList.remove(styles['header--body__overflow'])
  }, [isVisible])

  return (
    <HeaderContext.Provider value={contenfulData}>
      <CategoriesWrapper />
      {children}
      <HeaderV3
        headerBanner={showBanner && <BannerBottom />}
        mainHeaderProps={{
          ...headerProps,
          search: <SearchHeaderWrapper isVisible={isVisible} />
        }}
      />
      <SSOWelcomeModal />
      <HeaderClubBenefitsModal />
      <HeaderClubSavingModal />
      <BigScreen>
        {isVisible && (
          <Overlay
            onClick={handleClose}
            zIndex='1'
          />
        )}
      </BigScreen>
    </HeaderContext.Provider>
  )
}
