import { useContext } from 'react'
import Link from 'next/link'
import { Column, Overlay } from '@smu-chile/pkg-unimarc-components'
import { Categories } from '@smu-chile/pkg-unimarc-components/stories/organisms/HeaderPrototype/Categories'
import {
  trigger,
  useCategories,
  useEvents,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { aislesProps } from 'components/HeaderBrowse/helpers'
import { HeaderContext } from 'components/HeaderWrapper'
import { IHeaderData } from 'shared/interfaces/IHeaderContentful'
import { useCategoriesWrapper } from './hooks/useCategoriesWrapper'

export const CategoriesWrapper = () => {
  const headerContext: IHeaderData = useContext(HeaderContext)
  const resultAisles = useCategories({ level: 2 })
  const { isMobileV2: isMobile } = useMobile()
  const {
    ref,
    isOpen,
    handleOpen,
    categoriesDataImage,
    categoriesBannerImage,
    offersMobile
  } = useCategoriesWrapper()

  useEvents({
    eventType: 'categoriesWrapperOpen',
    callBack: () => {
      handleOpen(!isOpen)
    }
  })

  const handleFacetsRefetch = () => {
    trigger({
      eventType: 'refetchFacets'
    })
  }

  const aislesPropsData = aislesProps({
    categoriesDataImage,
    categoriesBannerImage,
    isMobile,
    offersMobile,
    resultAisles
  })

  const categoriesProps = {
    activeColor: headerContext?.activeButtonColor,
    categoriesData: aislesPropsData,
    colorHover: headerContext?.hoversHeaderButtons,
    colorTextHeaderPrincipal: headerContext?.colorTextHeaderPrincipal,
    showMenuButton: false
  }

  return (
    <Column
      minHeight='100vh'
      position='absolute'
      ref={ref}
    >
      <Categories
        {...categoriesProps}
        handleFacetsRefetch={handleFacetsRefetch}
        handleOpenMenu={() => {
          handleOpen(false)
        }}
        isActive={isOpen}
        linkWrapper={Link}
        site='unimarc'
      />
      {isOpen && (
        <Overlay
          onClick={() => {
            handleOpen(false)
          }}
        />
      )}
    </Column>
  )
}
