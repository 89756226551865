import { useCallback, useEffect, useRef, useState } from 'react'
import { Row, Column } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  useGetFavoriteProducts,
  deleteFavoriteProduct,
  postFavoriteProduct,
  useSession,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { IPostFavoriteProductRequest } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/postFavoriteProduct'
import { TooltipFeedback } from 'components/TooltipFeedback'
import router from 'next/router'

/**
 * Generates a unique ID for each request.
 */
const uniqueId = (() => {
  let count = 0
  return () => {
    count += 1
    return count.toString()
  }
})()

export const useFavoriteProductsManager = () => {
  const { isMobile } = useMobile()
  const { isLoggedIn } = useSession()
  let currentURL: URL | null = null
  if (typeof window !== 'undefined') {
    currentURL = new URL(window.location.href)
  }
  // Toast states
  const [toast, setToast] = useState(false)
  const [textToast, setTextToast] = useState('')
  const [linTextToast, setLinkTextToast] = useState('')
  const [typeToast, setTypeToast] = useState<
    'success' | 'error' | 'warning' | 'info' | 'feedback'
  >('feedback')
  const [errorCode, setErrorCode] = useState('')

  // For undo ("Deshacer") if user removed a favorite
  const [lastRemovedPayload, setLastRemovedPayload] =
    useState<IPostFavoriteProductRequest | null>(null)
  const [lastAction, setLastAction] = useState<'add' | 'remove' | null>(null)

  // "inMemory" map of productId => boolean (favorite?), updated instantly on click
  const [favoriteInMemory, setFavoriteInMemory] = useState<
    Record<string, boolean>
  >({})

  // A dictionary for pending requests, to allow cancellation or ignoring old requests
  const pendingRequests = useRef<{
    [productId: string]: {
      requestId: string
      oldValue: boolean
    }
  }>({})

  /**
   * Opens login flow if not logged.
   */
  const paramLogin = () => {
    // Verifica que currentURL exista (no es SSR)
    if (!currentURL) {
      return
    }
    currentURL.searchParams.append('login', 'true')
    router.push(
      {
        pathname: currentURL.pathname.toString(),
        query: currentURL.searchParams.toString()
      },
      undefined,
      { shallow: true }
    )
  }

  /**
   * Fetches the list of favorite products.
   */
  const {
    data: dataFavoriteProducts,
    isLoading: isLoadingFavoriteProducts,
    isError: isErrorFavoriteProducts,
    refetch
  } = useGetFavoriteProducts(
    { all: true, order: '1' },
    {
      enabled: isLoggedIn,
      retry: 2,
      retryDelay: 3000,
      cacheTime: 5 * 60 * 1000, // 5min
      staleTime: 60 * 1000, // 1min
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  )

  /**
   * Adds a product to favorites, then refetches if success.
   */
  const addFavorite = useCallback(
    async (payload: IPostFavoriteProductRequest) => {
      if (!isLoggedIn) {
        paramLogin()
        return { error: true, code: 'login' }
      }
      const response = await postFavoriteProduct(payload)
      if (!response?.error) {
        await refetch()
      }
      return response
    },
    [isLoggedIn, refetch]
  )

  /**
   * Removes a product from favorites, then refetches if success.
   */
  const removeFavorite = useCallback(
    async (productId: string) => {
      if (!isLoggedIn) {
        paramLogin()
        return { error: true, code: 'login' }
      }
      const response = await deleteFavoriteProduct(productId)
      if (!response?.error) {
        await refetch()
      }
      return response
    },
    [isLoggedIn, refetch]
  )

  /**
   * Checks if a product is favorite, considering favoriteInMemory overrides.
   */
  const isProductFavorite = useCallback(
    (productId: string): boolean => {
      if (favoriteInMemory[productId] !== undefined) {
        return favoriteInMemory[productId]
      }
      return (
        dataFavoriteProducts?.items?.some((fav) => {
          return fav.productId === productId
        }) ?? false
      )
    },
    [dataFavoriteProducts, favoriteInMemory]
  )

  /**
   * Toggles favorite status for a product.
   * - Cancels old request if any.
   * - Immediately updates favoriteInMemory to change UI.
   * - If request fails, revert favoriteInMemory.
   */
  const handleToggleFavorite = useCallback(
    async (payload: IPostFavoriteProductRequest, nextValue: boolean) => {
      const productId = payload.productId

      // oldValue = the current favored state
      const oldValue = isProductFavorite(productId)

      // Cancel the previous request if it exists:
      if (pendingRequests.current[productId]) {
        const { oldValue: oldVal } = pendingRequests.current[productId]
        // revert UI to the oldVal
        setFavoriteInMemory((prev) => {
          return { ...prev, [productId]: oldVal }
        })
        delete pendingRequests.current[productId]
      }

      // update inMemory instantly
      setFavoriteInMemory((prev) => {
        return { ...prev, [productId]: nextValue }
      })

      // store the request with unique ID
      const requestId = uniqueId()
      pendingRequests.current[productId] = {
        requestId,
        oldValue
      }

      try {
        let response
        if (nextValue) {
          // Add flow
          setLastAction('add')
          response = await addFavorite(payload)
          // if user canceled again => ignore
          if (pendingRequests.current[productId]?.requestId !== requestId) {
            return
          }
          if (!response?.error) {
            // success
            setTextToast(
              `❤️${isMobile ? `\u2003` : ` `}Se agregó a Mis favoritos`
            )
            setLinkTextToast('Revisar')
            setTypeToast('feedback')
            setErrorCode('')
            setToast(true)
          } else {
            // fail => revert
            setFavoriteInMemory((prev) => {
              return { ...prev, [productId]: oldValue }
            })
            setTextToast(
              'No pudimos actualizar tus favoritos. Intenta nuevamente'
            )
            setLinkTextToast('')
            setTypeToast('error')
            setErrorCode(response?.code ?? '500')
            setToast(true)
          }
        } else {
          // Remove flow
          setLastAction('remove')
          setLastRemovedPayload(payload)
          response = await removeFavorite(productId)
          if (pendingRequests.current[productId]?.requestId !== requestId) {
            return
          }
          if (!response?.error) {
            // success
            setTextToast(
              `🥺${isMobile ? `\u2003` : ` `}Se eliminó de Mis favoritos`
            )
            setLinkTextToast('Deshacer')
            setTypeToast('feedback')
            setErrorCode('')
            setToast(true)
          } else {
            // fail => revert
            setFavoriteInMemory((prev) => {
              return { ...prev, [productId]: oldValue }
            })
            setTextToast(
              'No pudimos actualizar tus favoritos. Intenta nuevamente.'
            )
            setLinkTextToast('')
            setTypeToast('error')
            setErrorCode(response?.code ?? '500')
            setToast(true)
          }
        }
      } catch (err) {
        // If still the same request => revert
        if (pendingRequests.current[productId]?.requestId === requestId) {
          setFavoriteInMemory((prev) => {
            return { ...prev, [productId]: oldValue }
          })
        }
        setTextToast('No pudimos actualizar tus favoritos. Intenta nuevamente')
        setLinkTextToast('')
        setTypeToast('error')
        setErrorCode('500')
        setToast(true)
      } finally {
        // remove the pending request if it's still the same
        if (pendingRequests.current[productId]?.requestId === requestId) {
          delete pendingRequests.current[productId]
        }
      }
    },
    [addFavorite, removeFavorite, isProductFavorite]
  )

  /**
   * Action for the toast link: "Revisar" => goes to MyFavorites,
   * or "Deshacer" => re-add the lastRemoved product
   */
  const handleActionToast = useCallback(() => {
    if (lastAction === 'add') {
      router.push(process.env.NEXT_PUBLIC_MY_FAVORITES_URL || '/MyFavorites')
    } else if (lastAction === 'remove' && lastRemovedPayload) {
      handleToggleFavorite(lastRemovedPayload, true)
    }
  }, [lastAction, lastRemovedPayload, handleToggleFavorite])

  /**
   * Small sub-component to show the toast UI (the repeated Row/Column code).
   * You can use it from your front-end:
   * const { ToastFavoriteUI } = useFavoriteProductsManager();
   * Then <ToastFavoriteUI isMobile={isMobile} />
   */
  interface ToastFavoriteUIProps {
    isMobile: boolean
  }
  const handleClose = () => {
    setToast(false)
    setTextToast('')
    setErrorCode('')
    setLinkTextToast('')
  }

  useEffect(() => {
    const onScrollOrWheelOrTouch = () => {
      handleClose()
    }

    const eventOptions = { passive: true, capture: true }

    window.addEventListener('scroll', onScrollOrWheelOrTouch, eventOptions)
    window.addEventListener('wheel', onScrollOrWheelOrTouch, eventOptions)
    window.addEventListener('touchmove', onScrollOrWheelOrTouch, eventOptions)

    return () => {
      window.removeEventListener('scroll', onScrollOrWheelOrTouch, eventOptions)
      window.removeEventListener('wheel', onScrollOrWheelOrTouch, eventOptions)
      window.removeEventListener(
        'touchmove',
        onScrollOrWheelOrTouch,
        eventOptions
      )
    }
  }, [handleClose])

  const ToastFavoriteUI: React.FC<ToastFavoriteUIProps> = ({
    // eslint-disable-next-line react/prop-types
    isMobile = false
  }) => {
    if (!toast) {
      return null
    }

    // We'll read window.innerWidth only if window is defined:
    const defaultWidth =
      typeof window !== 'undefined' ? `${window.innerWidth}px` : '350px'
    const marginValue = isMobile
      ? typeToast === 'feedback'
        ? '10px 16px'
        : '10px 8px'
      : typeToast === 'feedback'
      ? '16px'
      : '10px 8px'

    return (
      <Row
        absoluteDefault={
          isMobile && typeToast !== 'error' ? 'bottomRight' : 'topLeft'
        }
        margin={
          isMobile
            ? typeToast === 'error'
              ? '35% 0'
              : '0 0 15% 0'
            : '11% 0 0 0'
        }
        position='fixed'
        zIndex={getGlobalStyle('--z-index-999999')}
      >
        <Column
          absoluteDefault={
            isMobile && typeToast === 'error' ? 'bottomRight' : 'topLeft'
          }
          alignItems='center'
          alignSelf='center'
          justifyContent='center'
          left={!isMobile ? '64%' : undefined}
          maxWidth={!isMobile ? 'fit-content' : undefined}
          position='absolute'
        >
          <TooltipFeedback
            borderRadius='--border-radius-md'
            closeIcon={typeToast === 'feedback' ? false : true}
            colorText={
              typeToast === 'error'
                ? getGlobalStyle('--color-base-black')
                : getGlobalStyle('--color-base-white')
            }
            customHeight={
              isMobile && typeToast === 'feedback' ? '60px' : '56px'
            }
            errorCode={errorCode}
            fontWeightText={typeToast === 'feedback' ? 'regular' : 'medium'}
            isMobile={isMobile}
            linkText={linTextToast}
            linkTextColor='--color-primary-light-red'
            maxWidth={
              isMobile && typeToast === 'feedback' ? defaultWidth : '350px'
            }
            onClickLink={handleActionToast}
            onClose={handleClose}
            paddingTooltip={marginValue}
            position='sticky'
            positionContainer='sticky'
            text={textToast}
            truncate={null}
            type={typeToast}
            width={
              isMobile && typeToast === 'feedback' ? defaultWidth : '350px'
            }
          />
        </Column>
      </Row>
    )
  }

  return {
    dataFavoriteProducts,
    errorCode,
    favoriteInMemory,
    isErrorFavoriteProducts,
    isLoadingFavoriteProducts,
    linTextToast,
    textToast,
    toast,
    typeToast,
    handleActionToast,
    handleClose,
    handleToggleFavorite,
    isProductFavorite,
    setErrorCode,
    setLinkTextToast,
    setTextToast,
    setToast,
    setTypeToast,
    ToastFavoriteUI
  }
}
