import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  useCheckUserData,
  useSession,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { shouldOpenModal } from 'components/HeaderBrowse/helpers'
import { useAuthWrapper } from './useAuthWrapper'

export const AuthWrapper = () => {
  const router = useRouter()
  const { isLoggedIn, isLoading } = useSession()
  const { data: dataMembership, isLoading: isLoadingMembership } =
    useUserWithMembership()
  const [loginModalStep, setLoginModalStep] = useState<string>(undefined)
  const [shouldCheckUser, setShouldCheckUser] = useState(false)
  const [isUserMissingData, setIsUserMissingData] = useState(false)

  const {
    handleOpenLoginModal,
    tagLoginRef,
    loginModalOpen,
    setLoginModalOpen
  } = useAuthWrapper()

  // primitive
  const shouldLoginModalBeOpen = shouldOpenModal(
    router.isReady,
    router.query.login,
    isLoading,
    isLoggedIn
  )

  //Check if user is still missing data after closing login.
  useCheckUserData(shouldCheckUser, isUserMissingData, setIsUserMissingData)

  const handleCloseLoginModal = async () => {
    handleOpenLoginModal(false)
    setShouldCheckUser(true)
    setLoginModalOpen(false)
  }

  useEffect(() => {
    setLoginModalOpen(isUserMissingData)
    setLoginModalStep(isUserMissingData ? 'missingUserData' : 'login')
  }, [isUserMissingData])

  useEffect(() => {
    setLoginModalOpen(shouldLoginModalBeOpen)
  }, [shouldLoginModalBeOpen])

  useEffect(() => {
    if (dataMembership?.code === 'USR-23') {
      setIsUserMissingData(true)
    }
  }, [dataMembership, isLoadingMembership])

  return (
    <LoginModalBrowse
      defaultFormStep={loginModalStep}
      isOpen={loginModalOpen}
      onClose={handleCloseLoginModal}
      router={router}
      tagLoginRef={tagLoginRef}
    />
  )
}
