import { slugify } from 'shared/helpers/slugify'
import { ClubBlockDiscounts } from './ClubBlockDiscounts'
import { IMembershipBlock } from 'shared/interfaces/IMembership'
import { ClubBlockBannersTabs } from './ClubBlockBannersTabs/ClubBlockBannersTabs'

export enum ClubBlockIds {
  BotonesMembresia = 'Botones membresia',
  CarruselDescuentosPorNivel = 'Carrusel descuentos por nivel',
  DescuentosPorNivelDesktop = 'Descuentos por nivel desktop',
  DescuentosPorNivelInvitadoDesktop = 'Descuentos por nivel invitado desktop',
  DescuentosPorNivelInvitadoMobile = 'Descuentos por nivel invitado mobile',
  DescuentosPorNivelMobile = 'Descuento por nivel mobile',
  PreguntasFrecuentes = 'Preguntas frecuentes',
  MembershipButtons = 'Botones Membresía'
}

export type ClubBlockProps = {
  blockId?: string
  description?: string
  isMobile?: boolean
  items?: unknown[]
  membershipData?: IMembershipBlock
  membershipLevel?: string
}

export const ClubBlock = ({ blockId, description, items }: ClubBlockProps) => {
  const isBlockId = (value: string) => {
    return slugify(value) === slugify(blockId)
  }

  const bannersTabsProps = () => {
    return {
      items,
      title: description
    }
  }

  return (
    <>
      {(isBlockId(ClubBlockIds.CarruselDescuentosPorNivel) ||
        isBlockId(ClubBlockIds.DescuentosPorNivelDesktop) ||
        isBlockId(ClubBlockIds.DescuentosPorNivelMobile)) && (
        <ClubBlockDiscounts
          description={description}
          items={items}
        />
      )}
      {isBlockId(ClubBlockIds.DescuentosPorNivelInvitadoDesktop) && (
        <ClubBlockBannersTabs {...bannersTabsProps()} />
      )}

      {isBlockId(ClubBlockIds.DescuentosPorNivelInvitadoMobile) && (
        <ClubBlockBannersTabs {...bannersTabsProps()} />
      )}

      {/** add here new blocks by block-id */}
    </>
  )
}
