import { ChangeEvent, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import Image from 'next/future/image'

import {
  BackboneShelves,
  Column,
  FontSizeLink,
  Link as LinkComponent,
  Row,
  ShelfCarousel,
  ShelfProps,
  Title,
  Sizes,
  Picture,
  Container
} from '@smu-chile/pkg-unimarc-components'
import { ChildrenBaseContainerProps } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import {
  addToHomeData,
  productClickTag,
  sleep,
  visiblePromos
} from '@smu-chile/pkg-unimarc-hooks'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { CarouselFromContentful } from 'shared/interfaces/Home'
import { CustomTitles } from 'components/Home'
import classNames from 'classnames'
import { useFavoriteProductsManager } from 'shared/hooks'
import styles from './ProductsCarousel.module.css'

export interface CarouselShelfData {
  positionContainer: ChildrenBaseContainerProps
  productsMemo: ShelfProps[]
  itemsToShow: number
  itemsToSlide: number
  spacerBelow: Sizes
  handleOnClick: (props?: { itemId: string }) => void
  handleAdd: (props?: { itemId: string }) => void
  handleRemove: (props?: { itemId: string }) => void
  handleOpenMyListsModal?: (props: IProductInList) => void
  handleChange: (
    e?: ChangeEvent<HTMLInputElement>,
    props?: { itemId: string }
  ) => void
}

export interface ContentCarouselProps {
  carouselHeaderPadding: string
  carouselShelData: CarouselShelfData
  clusterData: CarouselFromContentful
  isLoading: boolean
  isMobile: boolean
  linkFontSize: FontSizeLink
  seeAllUrl?: string
  titleSizes: CustomTitles
  typeCarousel?: string
}

export const ContentCarousel = ({
  carouselHeaderPadding,
  carouselShelData,
  clusterData,
  isLoading,
  isMobile,
  linkFontSize,
  seeAllUrl,
  titleSizes
}: ContentCarouselProps) => {
  const iconSize = isMobile ? '21px' : '30px'
  const icon = clusterData['sectionIcon']
    ? `https:${clusterData['sectionIcon']}`
    : null

  const backgroundImage = clusterData['backgroundImage']
    ? `https:${clusterData['backgroundImage']}`
    : null

  const backgroundRef = useRef(null)
  const [marginTop, setMarginTop] = useState(0)

  const getBackgroundImageContainerPadding = (
    isMobile: boolean,
    backgroundImage: string
  ) => {
    if (isMobile) {
      return backgroundImage ? '12px 0px 0px 0px' : '12px 12px 0 12px'
    }
    return 'none'
  }

  useEffect(() => {
    const handleBackgroundImageMarginTop = async () => {
      if (backgroundRef.current) {
        await sleep(500)
        const background = backgroundRef.current
        const halfHeight = background.offsetHeight / 2
        setMarginTop(halfHeight)
      }
    }
    handleBackgroundImageMarginTop()
  }, [backgroundRef, backgroundImage])

  const { handleToggleFavorite, isProductFavorite, ToastFavoriteUI } =
    useFavoriteProductsManager()

  const handleHomeShelf = ({ ref, shelf }) => {
    addToHomeData({
      ref,
      promoData: { ...shelf, promoTitle: clusterData?.title },
      isShelf: true
    })
  }

  return (
    <>
      <ToastFavoriteUI isMobile={isMobile} />
      <Row
        id={getBemId('myUsuals', 'container')}
        justifyContent='center'
        maxWidth='1120px'
        width={isMobile && backgroundImage ? '100vw' : ''}
      >
        {isLoading ? (
          <BackboneShelves
            shelvesQuantityDesktop={5}
            shelvesQuantityMobile={2}
          />
        ) : (
          <Column>
            {(clusterData?.title || clusterData?.subtitle) && (
              <Row
                alignItems='center'
                justifyContent='between'
                padding={
                  isMobile && backgroundImage ? '0 16px' : carouselHeaderPadding
                }
              >
                <Column customWidth='75'>
                  <Row>
                    <Container gap={4}>
                      {icon && (
                        <Picture
                          border='none'
                          hasURL={false}
                          height={iconSize}
                          src={icon}
                          width={iconSize}
                        />
                      )}
                      <Title
                        fontWeight={icon ? 'semibold' : 'medium'}
                        headingLevel={icon && !isMobile ? 'h3' : 'h1'}
                        text={clusterData['title']}
                      />
                    </Container>
                  </Row>
                  <Row>
                    <Title
                      fontWeight='regular'
                      headingLevel='h3'
                      {...titleSizes?.h3}
                      text={clusterData.subtitle}
                    />
                  </Row>
                </Column>
                <Column
                  alignItems='end'
                  customWidth='max-content'
                  justifyContent='between'
                >
                  {clusterData?.['url'] || seeAllUrl ? (
                    <Row
                      alignItems='center'
                      border={`1.2px solid ${getGlobalStyle(
                        '--color-neutral-black'
                      )}`}
                      borderRadius='24px'
                      justifyContent='center'
                      padding={isMobile ? '4px 10px' : '8px 16px'}
                    >
                      <LinkComponent
                        color={getGlobalStyle('--color-neutral-black')}
                        customClassName={classNames({
                          [styles['linkContainerMobile']]: isMobile
                        })}
                        fontSize={linkFontSize}
                        fontWeight='medium'
                        href={clusterData?.['url'] || seeAllUrl}
                        underline='none'
                        wrapper={Link}
                      >
                        Ver todos
                      </LinkComponent>
                    </Row>
                  ) : null}
                </Column>
              </Row>
            )}
            <Row
              padding={getBackgroundImageContainerPadding(
                isMobile,
                backgroundImage
              )}
            >
              {backgroundImage ? (
                <Container customClassName={styles['BackgroundImageContainer']}>
                  <div
                    className={styles['BackgroundContentCarouselImage']}
                    ref={backgroundRef}
                  >
                    <Picture
                      border='none'
                      hasURL={false}
                      height='auto'
                      src={backgroundImage}
                      width='100%'
                    />
                  </div>
                  <Container
                    customClassName={styles['CarouselContainer']}
                    style={{
                      marginTop: marginTop ? `${marginTop}px` : '280px'
                    }}
                  >
                    <ShelfCarousel
                      carouselProps={{
                        infinite: true,
                        autoPlay: false,
                        shouldResetAutoplay: false,
                        afterChange: () => {
                          return visiblePromos({})
                        },
                        showDots: false,
                        partialVisible: true
                      }}
                      catchShelfRef={handleHomeShelf}
                      containerProps={carouselShelData.positionContainer}
                      customClassNameItem={
                        !isMobile && 'personalizedOffersItem'
                      }
                      handleOnClick={carouselShelData.handleOnClick}
                      handleOpenMyListsModal={
                        carouselShelData?.handleOpenMyListsModal
                      }
                      isMobile={isMobile}
                      isProductFavorite={isProductFavorite}
                      items={carouselShelData.productsMemo}
                      itemsToShow={carouselShelData.itemsToShow}
                      linkWrapper={Link}
                      nextImage={Image}
                      onClickProduct={(product) => {
                        productClickTag({
                          ...product,
                          listIdVal: `home_${clusterData['title'].replace(
                            / /g,
                            '_'
                          )}`,
                          listNameVal: `Home ${clusterData['title']}`
                        })
                      }}
                      onToggleFavorite={handleToggleFavorite}
                      quantityButtonProps={{
                        handleAdd: carouselShelData.handleAdd,
                        handleRemove: carouselShelData.handleRemove,
                        handleChange: carouselShelData.handleChange
                      }}
                      quantityButtonStyles={{
                        fontSize: 'md',
                        margin: '0px',
                        maxHeight: '30px',
                        maxWidth: '163px',
                        sizeCircle: 22
                      }}
                      showAddToFavoriteButton={true}
                      showAddToListButton={true}
                      showArrows={!isMobile}
                      showCouponButton={false}
                      slidesToSlide={carouselShelData.itemsToSlide}
                    />
                  </Container>
                </Container>
              ) : (
                <ShelfCarousel
                  carouselProps={{
                    infinite: true,
                    autoPlay: false,
                    shouldResetAutoplay: false,
                    afterChange: () => {
                      return visiblePromos({})
                    }
                  }}
                  catchShelfRef={handleHomeShelf}
                  containerProps={carouselShelData.positionContainer}
                  handleOnClick={carouselShelData.handleOnClick}
                  handleOpenMyListsModal={
                    carouselShelData?.handleOpenMyListsModal
                  }
                  isMobile={isMobile}
                  isProductFavorite={isProductFavorite}
                  items={carouselShelData.productsMemo}
                  itemsToShow={carouselShelData.itemsToShow}
                  linkWrapper={Link}
                  nextImage={Image}
                  onClickProduct={(product) => {
                    productClickTag({
                      ...product,
                      listIdVal: `home_${clusterData['title'].replace(
                        / /g,
                        '_'
                      )}`,
                      listNameVal: `Home ${clusterData['title']}`
                    })
                  }}
                  onToggleFavorite={handleToggleFavorite}
                  quantityButtonProps={{
                    handleAdd: carouselShelData.handleAdd,
                    handleRemove: carouselShelData.handleRemove,
                    handleChange: carouselShelData.handleChange
                  }}
                  quantityButtonStyles={{
                    fontSize: 'md',
                    margin: '0px',
                    maxHeight: '30px',
                    maxWidth: '163px',
                    sizeCircle: 22
                  }}
                  showAddToFavoriteButton={true}
                  showAddToListButton={true}
                  showArrows={!isMobile}
                  showCouponButton={false}
                  slidesToSlide={carouselShelData.itemsToSlide}
                />
              )}
            </Row>
          </Column>
        )}
      </Row>
    </>
  )
}
