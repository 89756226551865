import { Backbone, Column, Row } from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { v4 as uuid } from 'uuid'
import styles from './Backbones.module.css'

export const PromoCombineModalBackbone = () => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const shelfArray = Array(12).fill(0)
  return (
    <Column
      gap='16px'
      padding='16px'
    >
      <Column gap='10px'>
        <span className={styles['title__backbone--desktop']}>
          <Backbone />
        </span>
        <span className={styles['title__backbone--desktop']}>
          <Backbone />
        </span>
      </Column>
      <Row
        gap={isMobile ? '24px' : '8px'}
        isWrap={true}
      >
        {shelfArray.map(() => {
          return (
            <Column
              alignItems='center'
              background='red'
              justifyContent='center'
              key={uuid()}
              minHeight='275px'
              width='166px'
            >
              {/* image */}
              <span className={styles['shelf__image--desktop']}>
                <Backbone />
              </span>
              <Row
                alignItems='end'
                gap='5px'
                justifyContent='between'
                margin='5px 0'
                width='150px'
              >
                <span className={styles['shelf__price--desktop']}>
                  <Backbone />
                </span>
                <span className={styles['shelf__button--desktop']}>
                  <Backbone />
                </span>
              </Row>
              <Column
                gap='5px'
                overflow='hidden'
                width='150px'
              >
                <span className={styles['shelf__content--desktop']}>
                  <Backbone />
                </span>
                <span className={styles['shelf__content--desktop']}>
                  <Backbone />
                </span>
                <span className={styles['shelf__content--desktop']}>
                  <Backbone />
                </span>
              </Column>
              <Row
                gap='15px'
                margin='11px 0 0'
                width='150px'
              >
                <span className={styles['shelf__bottom--desktop']}>
                  <Backbone />
                </span>
                <span className={styles['shelf__bottom--desktop']}>
                  <Backbone />
                </span>
              </Row>
            </Column>
          )
        })}
      </Row>
    </Column>
  )
}
