import { useContext, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
  productClickTag,
  useCategories,
  useEvents,
  useMobile,
  useOrderForm,
  useQueryClient,
  useSearchV2,
  useSession,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Column,
  CustomPortal,
  IconProps,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  SearchV2,
  SearchV2Props
} from '@smu-chile/pkg-unimarc-components/stories/organisms/SearchV2/SearchV2'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { HeaderContext } from 'components/HeaderWrapper'
import { IHeaderData } from 'shared/interfaces/IHeaderContentful'
import { aislesProps, searchProps } from 'components/HeaderBrowse/helpers'
import { DropDownSearchV2 } from '@smu-chile/pkg-unimarc-components/stories/organisms/SearchV2/DropDownSearchV2/DropDownSearchV2'
import { ILastSeenProducts } from 'shared/interfaces/ILastSeenProducts'
import { ProductDetailModalWrapper } from 'components/ProductDetailModalWrapper'
import { ModalProps } from '@smu-chile/pkg-unimarc-components/stories/molecules/Modal/helpers'
import { useSearchHeaderWrapper } from './hook/useSearchHeaderWrapper'
import styles from './SearchHeaderWrapper.module.css'

interface SearchHeaderWrapperProps {
  isVisible?: boolean
}

export const SearchHeaderWrapper = ({
  isVisible
}: SearchHeaderWrapperProps) => {
  const headerContext: IHeaderData = useContext(HeaderContext)
  const router = useRouter()
  const { isMobileV2: isMobile } = useMobile()
  const { isLoggedIn } = useSession()
  const { result, handleChangeSearch } = useSearchV2()
  const inputAdornmentRef = useRef(null)
  const columnSearchRef = useRef(null)
  const queryClient = useQueryClient()
  const resultAisles = useCategories({ level: 2 })
  const { isLoading: isOrderFormLoading } = useOrderForm()

  const [hasLocalAddress, setHasLocalAddress] = useState(false)
  const [isLoadingNextSearch, setIsLoadingNextSearch] = useState(false)
  const [value, setValue] = useState('')
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const [productSlug, setProductSlug] = useState('')

  const {
    categoriesDataImage,
    categoriesBannerImage,
    offersMobile,
    setIsVisible,
    isVisibleClearIcon,
    ref,
    isFocused,
    setIsFocused,
    setIsVisibleClearIcon
  } = useSearchHeaderWrapper()

  const { ...useUpdateProps } = useUpdateFromShelf({
    fromWhere: 'search',
    isOrderFormLoading,
    hasLocalAddress
  })

  useEvents({
    eventType: 'localAddressStoreLocator',
    callBack: (e: CustomEvent) => {
      setHasLocalAddress(e?.detail?.hasLocalAddress)
    }
  })

  const handleModalClose = () => {
    setIsOpenModalDetail(false)
    setProductSlug('')
  }

  const handleOnClickProduct = async (
    slug: string,
    item?: ILastSeenProducts
  ) => {
    setProductSlug(slug)
    productClickTag({
      product: item,
      site: 'Unimarc',
      listIdVal: 'SRP_vistos recientemente',
      listNameVal: 'SRP - vistos recientemente',
      isSearch: true
    })
    setIsOpenModalDetail(true)
  }

  const modalProps: ModalProps = isMobile
    ? {
        styleProps: {
          borderRadius: 'var(--border-radius-md) var(--border-radius-md) 0 0',
          padding: '16px 0 0 0'
        },
        iconsProps: {
          customCloseIcon: 'Close' as IconProps['name'],
          customClassNameCloseIcon: styles.modalCloseIcon,
          sizeIconClose: 20,
          iconPadding: '0 16px 10px 16px'
        },
        dragProps: {
          dragRatio: 0.2,
          draggButton: false
        },
        modalConfigsProps: {
          isWindowBlocked: true,
          isOpen: isOpenModalDetail,
          toggle: handleModalClose,
          toggleOutside: handleModalClose,
          openAnimation: true,
          maxWidth: 'max-content',
          fullScreenSticky: true,
          isAutoHeight: true
        }
      }
    : {
        styleProps: {
          height: 'max-content',
          padding: '0'
        },
        iconsProps: {
          iconActionHover: true,
          iconPadding: '20px',
          sizeIconClose: 16
        },
        modalConfigsProps: {
          idList: { rightIcon: 'headerBrowse__productDetailModalCloseIcon' },
          isOpen: isOpenModalDetail,
          maxHeight: '550px',
          maxWidth: '837px',
          toggle: handleModalClose,
          toggleOutside: handleModalClose
        }
      }

  const aislesPropsData = aislesProps({
    categoriesDataImage,
    categoriesBannerImage,
    isMobile,
    offersMobile,
    resultAisles
  })

  const searchPropsData = searchProps({
    ...useUpdateProps,
    inputAdornmentRef,
    isLoadingNextSearch,
    isMobile,
    isVisible,
    isVisibleClearIcon,
    queryClient,
    result,
    router,
    value,
    handleChangeSearch,
    setActiveMenu: () => {
      return
    },
    setIsFocused,
    setIsVisible,
    setIsVisibleClearIcon,
    setIsLoadingNextSearch,
    setValue
  })

  const searchPropsV2: SearchV2Props = {
    ...searchPropsData,
    backgroundDropDownSearch: headerContext?.backgroundDropDownSearch,
    backgroundGradientIconSearch: headerContext?.colorBackgroundSearch,
    backgroundIconSearch: getGlobalStyle('--color-base-transparent'),
    backgroundSearch: headerContext?.colorBackgroundSearch,
    borderSearch: `1px solid ${headerContext?.colorBackgroundSearch}`,
    colorBackgroundSearch: headerContext?.colorBackgroundSearch,
    colorIcon: headerContext?.colorTextSearch,
    colorTextSearch: headerContext?.colorTextSearch,
    columnSearchRef,
    customClassInputSearch: styles['search-header__input'],
    handleOnClickProduct,
    iconSearch: 'SearchIcon',
    iconWidth: '15',
    id: getBemId('search-header', 'input'),
    isLoggedIn,
    isMobile,
    isVisibleDropDownSearch: searchPropsData?.isVisibleDropDownSearch,
    linkWrapper: Link,
    router,
    searchPlaceholder: 'Buscar mis productos',
    site: 'unimarc',
    source: router.asPath
  }

  return (
    <>
      <Column
        ref={ref}
        zIndex='9'
      >
        <SearchV2
          {...searchPropsV2}
          categoriesData={{ ...aislesPropsData }}
          isFocusedInput={isFocused}
        />
        {searchPropsData?.isVisibleDropDownSearch && !isMobile && (
          <Row
            id='headerPrototype__dropdownSearchContainer'
            margin='57px 0 0'
            position='absolute'
            ref={columnSearchRef}
          >
            <CustomPortal rootId='portal-header'>
              <DropDownSearchV2
                {...searchPropsData?.dropDownSearch}
                backgroundDropDownSearch={
                  searchPropsV2?.backgroundDropDownSearch
                }
                categoriesData={{ ...aislesPropsData }}
                customClassNameInput={searchPropsV2?.customClassInputSearch}
                handleOnClickProduct={handleOnClickProduct}
                handleSearchImpressions={
                  searchPropsV2?.dropDownSearch?.bodySearch
                    ?.handleSearchImpressions
                }
                isLoggedIn={isLoggedIn}
                isMobile={isMobile}
                linkWrapper={Link}
              />
            </CustomPortal>
          </Row>
        )}
      </Column>
      {isOpenModalDetail && (
        <ProductDetailModalWrapper
          columnSearchRef={columnSearchRef}
          handleModalClose={handleModalClose} //for similar modals
          isMobile={isMobile}
          isSearch={true}
          modalProps={modalProps}
          productCouponModal={{}}
          productSlug={productSlug}
        />
      )}
    </>
  )
}
