import {
  IContentfulBasicEntry,
  IContentfulResponse,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { BannerHome } from 'shared/interfaces/Home'

/**
 * Handle banners order to insert sponsored banners into contenful home content
 * @param {BannerHome | IContentfulResponse} banners - Contentful home response banners
 * @param {ISponsoredBannersHome} sponsored - Sponsored banners
 * @returns {BannerHome | IContentfulResponse} Return an object with banners
 */
export const handleSponsoredBannersHome = (
  banners,
  sponsored
): BannerHome | IContentfulResponse => {
  if (isValidArrayWithData(sponsored)) {
    const indexCategories =
      Object.values(banners).findIndex((banner: IContentfulBasicEntry) => {
        return banner.label === 'UNIMARC_WEB_Categories'
      }) || 1

    const bannersWithSponsor = [
      ...Object.entries(banners).slice(0, indexCategories + 1),
      [
        'sponsoredBanners',
        {
          label: 'SPONSORED_BANNERS_ANDES',
          imageBanner: sponsored,
          itemsToShow: sponsored.length,
          layout: 'sponsor'
        }
      ],
      ...Object.entries(banners).slice(
        indexCategories + 1,
        Object.values(banners).length
      )
    ]

    return Object.fromEntries(bannersWithSponsor)
  }
  return banners
}
