export const headerWrapperQuery = `query {
  headerWeb(id: "2QTKI18u8gUYqy0HQEZ6NF") {
		activeButtonColor
		backgroundColorPrimary
		colorBackgroundNumberCartIcon
		colorLogoHeader
		colorNumberCartIcon
		colorTextHeaderPrincipal
		hoversHeaderButtons
		backgroundDropDownSearch
		colorBackgroundSearch
		colorTextSearch
		customUserMenuHover
		offersLinksCollection {
			items {
				url
				title
				target
				labelNewText
				labelColorText
				showLabel
				labelColorBackground
				target
			}
		}
		secondaryHeaderLinksCollection{
			items {
				url
				title
				target
				labelNewText
				labelColorText
				showLabel
				labelColorBackground
				target
			}
		}
		rightButtonsHeaderCollection {
			items {
				url
				title
				target
				labelNewText
				labelColorText
				showLabel
				labelColorBackground
				target
			}
		}
		menuUserButtonsCollection {
			items {
				url
				title
				target
				labelNewText
				labelColorText
				showLabel
				labelColorBackground
				target
			}
		}
	}
}
`
