import { GetServerSidePropsContext } from 'next'
import { getCategories, QueryClient } from '@smu-chile/pkg-unimarc-hooks'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICategories'
import { paginationCal } from './paginationCal'
import { matchOptionKey } from './filterOptions'
import { getHeaderCookies } from '.'
import { getInitialFilters, getQueryKeyToRequest } from './getDataFilters'
import { postProductsSearch } from '@smu-chile/pkg-unimarc-hooks/services/postProductsSearch'

interface GetServerSidePropsCategory {
  categoryData: Data
  intelliSearchData?: null
  error: boolean
  queryClient: QueryClient
  intelliSearchKeyName?: (string | string[])[]
}

export const normalizeCategory = (category: string) => {
  return category
    .normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/,/g, '')
    ?.replace(/\s/g, '-')
    .toLowerCase()
}

export const changeUrlCategories = (breadcrumbData) => {
  const breadcrumb = breadcrumbData?.map?.((breadcrumbItem) => {
    if (breadcrumbItem?.label !== 'Inicio') {
      breadcrumbItem['url'] = `/category${breadcrumbItem?.url}`
    }
    return breadcrumbItem
  })

  breadcrumb[breadcrumbData?.length - 1]['url'] = '#'
  const { label, url } = breadcrumb[breadcrumbData?.length - 1]
  breadcrumb[breadcrumbData?.length - 1] = {
    label,
    url
  }
  return breadcrumb
}

export const categoriesForBreadcrumbs = ({
  router,
  categories,
  resultAisles
}) => {
  let urlCategory = ''
  let categoryFq = ''
  let categoryId = ''
  let subCategories = []
  let categoryTemp = resultAisles?.data
  const handleOnClickBreadCrumbs = (url: string) => {
    return router.push(url)
  }

  const breadcrumbData =
    categories?.map((key) => {
      const typeChildren = categoryTemp?.data ? 'data' : 'children'
      const data =
        categoryTemp?.[typeChildren]?.find(({ name }) => {
          return normalizeCategory(name) === normalizeCategory(key)
        }) || {}
      categoryFq = data?.fq
      categoryId = data?.id
      categoryTemp = data
      urlCategory += `/${key}`
      subCategories = data?.children?.map(({ name }) => {
        return {
          label: name,
          url: `${urlCategory}/${normalizeCategory(name)}`
        }
      })

      return {
        label: data?.name,
        url: urlCategory,
        oneClick: handleOnClickBreadCrumbs
      }
    }) || []
  breadcrumbData?.unshift({
    label: 'Inicio',
    url: '/',
    oneClick: handleOnClickBreadCrumbs
  })
  const title = breadcrumbData[breadcrumbData?.length - 1].label

  return {
    breadcrumbData,
    categoryFq,
    subCategories,
    title,
    categoryId
  }
}

type QueryObject = { [key: string]: string | string[] }
const convertValuesToArray = (queries: QueryObject) => {
  const result: QueryObject = {}
  for (const key in queries) {
    if (key === 'categories') continue
    const value = queries[key]
    if (typeof value === 'string') {
      result[key] = value.includes(',') ? value.split(',') : [value]
    } else {
      result[key] = value
    }
  }
  return result
}
export const getServerSidePropsCategory = async (
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsCategory> => {
  const query = context?.query
  const queryClient = new QueryClient()
  const categoriesOfQuery = query?.categories
  const page = parseInt(query?.page as string) || 1
  const checkQueryArray = Array.isArray(categoriesOfQuery)
    ? categoriesOfQuery
    : [categoriesOfQuery]

  const categories = await getCategories(2)
  let temporalCategories = categories

  const checkCategory = checkQueryArray
    .map((key) => {
      const typeChildren = temporalCategories?.['data'] ? 'data' : 'children'
      const categoryMatch = temporalCategories?.[typeChildren]?.find(
        ({ name }) => {
          return normalizeCategory(name) === normalizeCategory(key)
        }
      )
      temporalCategories = categoryMatch
      return categoryMatch
    })
    .slice(-1)[0]

  if (!categories?.data || categories?.data.length === 0) {
    return {
      categoryData: null,
      intelliSearchData: null,
      error: true,
      queryClient
    }
  }

  const headers = getHeaderCookies(context)

  const categoriesId = checkCategory?.fq.split(':')[1]
  const categoryTree = checkCategory?.fqDescriptionSlug ?? ''
  const orderByFilterName = matchOptionKey(query?.orderBy?.toString() || '')
  const { offset, end } = paginationCal({ page, limit: 50 })
  const initialFilters = getInitialFilters(query)
  const queryObjectValuesToArray = convertValuesToArray(query)
  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    { value: { ...queryObjectValuesToArray } },
    { value: categoryTree },
    { value: offset.toString() },
    { value: end.toString() },
    { value: orderByFilterName }
  ])

  const intelliSearchKeyName = ['productsSearch-client', keysToQuery]

  // Prefetch products search
  await queryClient.prefetchQuery(intelliSearchKeyName, () => {
    return postProductsSearch({
      ...queryObjectValuesToArray,
      categories: categoryTree,
      from: offset.toString(),
      headers,
      orderBy: orderByFilterName,
      to: end.toString()
    })
  })

  return {
    categoryData: {
      ...checkCategory,
      fq: categoriesId,
      fqDescriptionSlug: categoryTree
    },
    error: false,
    queryClient,
    intelliSearchKeyName
  }
}
