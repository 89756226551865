import { ChangeEvent } from 'react'
import {
  Column,
  Container,
  InputLabel,
  InputToggleSwitch,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  FormField as FormFieldContent,
  FormFieldTypes,
  ValidationsType
} from 'shared/interfaces/Form'
import { sanitizeInputValue, validateError } from '../helpers/helpers'
import classNames from 'classnames'
import styles from '../Form.module.css'

interface FormFieldProps {
  formData: { [key: string]: unknown }
  formField: FormFieldContent
  handleInputChange?: (
    sectionId: string,
    fieldId: string,
    value: unknown
  ) => void
  sectionId: string
  labelsColor: string
}

export const FormField = ({
  formData,
  formField,
  handleInputChange,
  sectionId,
  labelsColor
}: FormFieldProps) => {
  const formFieldValue = formData?.[sectionId]?.[formField.formfieldId]

  const onInputChange = (e?: ChangeEvent<HTMLInputElement>) => {
    if (!handleInputChange) return
    handleInputChange(
      sectionId,
      formField.formfieldId,
      sanitizeInputValue(e.target.value, formField)
    )
  }
  const onSwitchChange = (value: boolean) => {
    handleInputChange(sectionId, formField.formfieldId, value)
  }

  return (
    <>
      {formField.type === FormFieldTypes.Input && (
        <Column>
          <Row gap={8}>
            {formField.validations === ValidationsType.PhoneNumber && (
              <InputLabel
                customClassName={classNames(styles['input-container'])}
                customInputClassName={classNames(styles['code-input'])}
                disabled={true}
                disabledText={true}
                error={
                  formFieldValue && validateError(formField, formFieldValue)
                }
                fontSize='lg'
                id='code'
                inputWidth='76px'
                label='Código'
                name='code'
                showXIcon={false}
                value='+569'
                variant='compact'
              />
            )}
            <InputLabel
              customClassName={classNames(styles['input-container'])}
              customInputClassName={classNames(styles['input'])}
              error={formFieldValue && validateError(formField, formFieldValue)}
              fontSize='lg'
              fullWidth={true}
              id={formField.formfieldId}
              label={formField.label}
              name={formField.formfieldId}
              onChange={onInputChange}
              showXIcon={false}
              value={formFieldValue}
              variant='compact'
            />
          </Row>
          {formFieldValue && validateError(formField, formFieldValue) && (
            <Container padding='6px 0 0 16px'>
              <Text
                color='error'
                fontSize='xs'
              >
                {formField?.errorMessage ?? 'El campo es invalido.'}
              </Text>
            </Container>
          )}
        </Column>
      )}
      {formField.type === FormFieldTypes.Switch && (
        <InputToggleSwitch
          handleSelectCheck={onSwitchChange}
          isSelectCheck={formFieldValue}
          label={formField.label}
          labelsColor={labelsColor}
        />
      )}
    </>
  )
}
