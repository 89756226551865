import { NextRouter } from 'next/router'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import {
  HeaderSideButtons,
  HeaderUserMenuButtons
} from '@smu-chile/pkg-unimarc-components/shared/interfaces/IHeaderV3'
import { IconNames } from '@smu-chile/pkg-unimarc-components'
import { IHeaderData } from 'shared/interfaces/IHeaderContentful'

const handleSideButtonClick = (
  isLoggedIn: boolean,
  onClick: () => void,
  url: string,
  router: NextRouter,
  target?: string
) => {
  if (/helpcenter/i.test(url)) {
    return router.push(url)
  }
  if (!isLoggedIn) {
    return onClick()
  }
  if (target === '_blank') return window.open(url)
  return router.push(url)
}

export const handleHeaderSideButtons = ({
  contenfulData,
  isLoggedIn,
  isMobile,
  onClickLogin,
  username,
  router
}: {
  contenfulData: IHeaderData
  isLoggedIn: boolean
  isMobile: boolean
  onClickLogin: () => void
  username?: string
  router?: NextRouter
}): {
  sideButtons: HeaderSideButtons[]
  userMenuButtons: HeaderUserMenuButtons[]
} => {
  const sideButtonsContentful = isValidArrayWithData(
    contenfulData?.rightButtonsHeaderCollection?.items
  )
    ? contenfulData?.rightButtonsHeaderCollection?.items.map((button) => {
        let iconName

        const handleClick = () => {
          handleSideButtonClick(
            isLoggedIn,
            onClickLogin,
            button?.url,
            router,
            button?.target
          )
        }

        if (/favorito/i.test(button.title)) iconName = 'Heart2' as IconNames
        if (/compras/i.test(button.title)) iconName = 'BagUnimarc' as IconNames

        return {
          buttonText: button?.title,
          iconName,
          onClick: handleClick
        }
      })
    : []

  const sideButtons = [
    ...sideButtonsContentful,
    {
      buttonText: 'Inicia sesión',
      iconName: isLoggedIn ? ('UserTick' as IconNames) : ('User3' as IconNames),
      username
    },
    {
      buttonText: '',
      iconName: 'Cart2' as IconNames
    }
  ]

  const sideButtonMobile = [
    {
      buttonText: 'Inicia sesión',
      iconName: isLoggedIn ? ('UserTick' as IconNames) : ('User3' as IconNames),
      username: isLoggedIn ? 'Mi Cuenta' : null,
      onClick: () => {
        handleSideButtonClick(
          isLoggedIn,
          onClickLogin,
          process.env.NEXT_PUBLIC_MYORDERSURL,
          router,
          '_self'
        )
      }
    },
    {
      buttonText: '',
      iconName: 'Cart2' as IconNames,
      onClick: () => {
        handleSideButtonClick(
          isLoggedIn,
          onClickLogin,
          process.env.NEXT_PUBLIC_CARTURL,
          router,
          '_self'
        )
      }
    }
  ]

  const userMenuButtonsContentful = isValidArrayWithData(
    contenfulData?.menuUserButtonsCollection?.items
  )
    ? contenfulData?.menuUserButtonsCollection?.items.map((button) => {
        let iconName

        const handleClick = () => {
          handleSideButtonClick(
            isLoggedIn,
            onClickLogin,
            button?.url,
            router,
            button?.target
          )
        }

        if (/cuenta/i.test(button.title)) iconName = 'User3' as IconNames
        if (/membresía/i.test(button.title))
          iconName = 'DiamondMembership' as IconNames
        if (/tarjetas/i.test(button.title)) iconName = 'Card' as IconNames
        if (/datos/i.test(button.title)) iconName = 'UserWithHeart' as IconNames
        if (/listas/i.test(button.title)) iconName = 'MyLists' as IconNames
        if (/favoritos/i.test(button.title)) iconName = 'Heart2' as IconNames
        if (/ayuda/i.test(button.title)) iconName = 'Headset' as IconNames
        if (/solicitudes/i.test(button.title))
          iconName = 'RequestTicketsZendesk' as IconNames

        return {
          buttonText: button?.title,
          iconName,
          onClick: handleClick,
          showLabel: button?.showLabel,
          label: {
            text: button?.labelNewText,
            color: button?.labelColorText,
            background: button?.labelColorBackground
          }
        }
      })
    : []

  const userMenuButtons = [...userMenuButtonsContentful]

  return {
    sideButtons: isMobile ? sideButtonMobile : sideButtons,
    userMenuButtons
  }
}
