import React, { useEffect, useRef } from 'react'
import {
  Column,
  Container,
  Icon,
  Row,
  Text,
  Tooltip
} from '@smu-chile/pkg-unimarc-components'
import type { IconNames } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { TTokens } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ITokens'
import { TFontWeight } from '@smu-chile/pkg-unimarc-components/shared/interfaces/TFontWeight'

type Position = 'absolute' | 'relative' | 'fixed' | 'initial' | 'sticky'
type FeedbackStyles = {
  background: string
  iconColor: string
  icon?: IconNames | string
}

type FeedbackTypes = {
  success: FeedbackStyles
  warning: FeedbackStyles
  error: FeedbackStyles
  info: FeedbackStyles
  feedback: FeedbackStyles
}

export interface ITooltipFeedback {
  borderRadius?: TTokens
  closeIcon?: boolean
  colorText?: string
  customHeight?: string
  errorCode?: string
  gap?: string
  iconMargin?: string
  fontWeightText?: TFontWeight
  linkText?: string
  linkTextColor?: TTokens
  marginText?: string
  isMobile?: boolean
  maxWidth?: string
  nestedTextComponent?: React.ReactElement
  paddingTooltip?: string
  position?: Position
  positionContainer?: Position
  text: string
  truncate?: number
  type: 'success' | 'error' | 'warning' | 'info' | 'feedback'
  width?: string
  onClickLink?: () => void
  /**
   * Closes the tooltip. Called automatically on:
   * - 4s timer
   * - scroll event
   * - outside click
   */
  onClose?: () => void
}

export const TooltipFeedback = ({
  borderRadius = '--border-radius-2sm',
  colorText = 'black',
  customHeight = '60px',
  errorCode,
  fontWeightText = 'medium',
  gap = '8px',
  iconMargin = '0 4px 0 0',
  isMobile,
  linkText = '',
  linkTextColor = '--color-feedback-success-bg',
  marginText = '0 8px 0 0',
  maxWidth = '343px',
  nestedTextComponent = null,
  paddingTooltip = '0 8px',
  position = 'sticky',
  positionContainer = 'sticky',
  text = 'Lista eliminada exitosamente',
  truncate = 74,
  type = 'success',
  width = '343px',
  onClose,
  onClickLink,
  closeIcon = true
}: ITooltipFeedback): React.ReactElement => {
  const tooltipRef = useRef<HTMLDivElement>(null)

  const feedbackStyles: FeedbackTypes = {
    success: {
      background: getGlobalStyle('--color-feedback-success-bg'),
      iconColor: getGlobalStyle('--color-feedback-success-light'),
      icon: 'CheckCircle'
    },
    warning: {
      background: getGlobalStyle('--color-feedback-warning-bg'),
      iconColor: getGlobalStyle('--color-feedback-warning-light'),
      icon: 'WarningFilled'
    },
    error: {
      background: getGlobalStyle('--color-feedback-error-bg'),
      iconColor: getGlobalStyle('--color-feedback-error-light'),
      icon: 'InfoFill'
    },
    info: {
      background: getGlobalStyle('--color-feedback-info-bg'),
      iconColor: getGlobalStyle('--color-feedback-info'),
      icon: 'InfoFill'
    },
    feedback: {
      background: getGlobalStyle('--color-base-black'),
      iconColor: getGlobalStyle('--color-base-black'),
      icon: ''
    }
  }

  const computedBorderRadius =
    isMobile && type === 'feedback'
      ? `${getGlobalStyle(borderRadius)} ${getGlobalStyle(borderRadius)} 0 0`
      : getGlobalStyle(borderRadius)

  useEffect(() => {
    if (!onClose) return

    const closeTimeout = setTimeout(() => {
      onClose()
    }, 4000)

    const handleScroll = () => {
      onClose()
    }
    window.addEventListener('scroll', handleScroll, { once: true })

    const handleDocClick = (evt: MouseEvent) => {
      if (!tooltipRef.current) return
      if (!tooltipRef.current.contains(evt.target as Node)) {
        onClose()
      }
    }
    document.addEventListener('click', handleDocClick)
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(closeTimeout)
      window.removeEventListener('scroll', handleScroll)
      document.removeEventListener('click', handleDocClick)
    }
  }, [onClose])

  return (
    <div ref={tooltipRef}>
      <Tooltip
        alignAllItems='center'
        background={feedbackStyles[type].background}
        borderRadius={computedBorderRadius}
        boxShadow={getGlobalStyle('--box-shadow-3md')}
        closeColor={getGlobalStyle('--color-icons-black')}
        closeIcon={closeIcon}
        display='flex'
        height={customHeight}
        iconMargin={iconMargin}
        id={getBemId('tooltipFeedback', 'tooltip', type)}
        maxWidth={maxWidth}
        onClick={onClose}
        padding={paddingTooltip}
        position={position}
        positionContainer={positionContainer}
        textComponent={
          <Container zIndex='1000'>
            <Row
              alignItems='center'
              gap={gap}
            >
              {type !== 'feedback' && (
                <Column
                  id={getBemId('tooltipFeedback', 'icon', type)}
                  maxWidth='max-content'
                >
                  <Icon
                    color={feedbackStyles[type].iconColor}
                    customSize={20}
                    name={feedbackStyles[type].icon as IconNames}
                  />
                </Column>
              )}
              <Column
                margin={marginText}
                width={type === 'feedback' ? '80%' : '100%'}
              >
                <Text
                  customColor={colorText}
                  display='inline'
                  fontSize='md'
                  fontWeight={fontWeightText}
                  id={getBemId('tooltipFeedback', 'text', type)}
                  truncate={truncate}
                  type='span'
                  width={type === 'feedback' ? '80%' : '100%'}
                >
                  {text} &nbsp;
                  {errorCode && (
                    <Text
                      color='gray'
                      display='inline'
                      fontSize='2xs'
                      type='span'
                    >
                      &nbsp;({errorCode})
                    </Text>
                  )}
                </Text>

                {nestedTextComponent}
              </Column>
              {linkText && (
                <Column
                  alignItems='end'
                  maxWidth='15%'
                >
                  <Text
                    clickable='pointer'
                    customColor={getGlobalStyle(linkTextColor)}
                    fontSize='md'
                    fontWeight='semibold'
                    id={getBemId('tooltipFeedback', 'linkText', type)}
                    onClick={onClickLink}
                  >
                    {linkText}
                  </Text>
                </Column>
              )}
            </Row>
          </Container>
        }
        type='bottom'
        width={width}
      />
    </div>
  )
}
