import { getAllianceModalInfo } from 'components/ClubAhorro/ClubAhorroContent/helpers/clubAhorroContent'
import confetti from './Animation/confetti.json'
import { useHandleActivateAlliance } from 'shared/helpers/alliances'
import {
  IAlliance,
  IAllianceResponse
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAlliance'
import {
  AxiosResponse,
  IResponse,
  UseQueryResult,
  datalayerSimpleEvent,
  trigger,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { AllianceModal, Lottie } from '@smu-chile/pkg-unimarc-components'
import { Dispatch, SetStateAction, useState } from 'react'
import { NextRouter } from 'next/router'

export interface AllianceModalHandlerProps {
  allianceCoupons: IAlliance[]
  alliances: UseQueryResult<
    AxiosResponse<IResponse<IAllianceResponse>>,
    unknown
  >
  isLoggedIn: boolean
  isMobile: boolean
  isOpen: boolean
  loadingError: boolean
  membershipLevel?: string
  router?: NextRouter
  selectedAlliance: IAlliance
  showAnimation: boolean
  setAllianceCoupons: Dispatch<SetStateAction<IAlliance[]>>
  setIsOpen: (isOpen: boolean) => void
  setOpenLoginModal: (isOpen: boolean) => void
  setSelectedAlliance: (selectedAlliance) => void
  setShowAnimation: (showAnimation: boolean) => void
}

export const AllianceModalHandler = ({
  allianceCoupons,
  alliances,
  isLoggedIn,
  isOpen,
  membershipLevel,
  loadingError,
  router,
  selectedAlliance,
  showAnimation,
  setAllianceCoupons,
  setIsOpen,
  setOpenLoginModal,
  setSelectedAlliance,
  setShowAnimation
}: AllianceModalHandlerProps) => {
  const [activationError, setActivationError] = useState(false)
  const handleActivateAlliance = useHandleActivateAlliance(
    alliances,
    membershipLevel
  )
  const { isMobile } = useMobile()

  const handleClickActivate = () => {
    if (!isLoggedIn) {
      setIsOpen(false)
      setOpenLoginModal(true)
    } else {
      handleActivateAlliance(
        isLoggedIn,
        allianceCoupons,
        selectedAlliance,
        setAllianceCoupons,
        setSelectedAlliance,
        setShowAnimation,
        setActivationError
      )
    }

    datalayerSimpleEvent({
      event: 'interacciones_club_unimarc',
      eventAction: 'clic_canjear',
      eventCategory: 'canje_beneficio',
      eventLabel: `${selectedAlliance.refid}`
    })
  }

  const handleModal = (allianceId) => {
    if (loadingError) {
      router.push('/club-unimarc')
    } else {
      setIsOpen(!isOpen)
      const alliance = allianceCoupons.find((alliance) => {
        return alliance.refid === allianceId
      })
      setSelectedAlliance(selectedAlliance ? null : alliance)
      setActivationError(false)
    }
  }

  const { available, title, text, modalTitle, image, legal } =
    getAllianceModalInfo(selectedAlliance, membershipLevel, isLoggedIn)

  const handleActivationCodeClick = () => {
    datalayerSimpleEvent({
      event: 'interacciones_club_unimarc',
      eventAction: 'clic_canjear',
      eventCategory: 'canje_beneficio',
      eventLabel: `${selectedAlliance.refid}`
    })
    window.open(selectedAlliance?.reference, '_blank')
  }

  const handleClickRedeem = () => {
    datalayerSimpleEvent({
      event: 'interacciones_club_unimarc',
      eventAction: 'clic_canjear',
      eventCategory: 'canje_beneficio',
      eventLabel: `${selectedAlliance.refid}`
    })
    window.open(selectedAlliance?.target, '_blank')
  }

  const handleClickShowLegal = () => {
    datalayerSimpleEvent({
      event: 'interacciones_club_unimarc',
      eventAction: 'clic_bases_legales',
      eventCategory: 'canje_beneficio',
      eventLabel: `${selectedAlliance.refid}`
    })
  }

  const handleAllianceEnable = () => {
    setSelectedAlliance(null)
    setIsOpen(false)

    if (isLoggedIn) {
      trigger({
        eventType: 'openMembershipModal',
        data: { eventContext: 'Mis Alianzas' }
      })
    } else {
      setOpenLoginModal(true)
    }
  }

  return (
    <AllianceModal
      activationError={activationError}
      allianceAvailable={available}
      allianceCode={selectedAlliance?.target}
      allianceStatus={selectedAlliance?.status}
      allianceType={selectedAlliance?.referenceType}
      animation={
        <Lottie
          animationData={confetti}
          autoPlay={false}
          loop={false}
        />
      }
      image={image}
      isLoggedIn={isLoggedIn}
      isMobile={isMobile}
      isOpen={isOpen}
      legal={legal}
      loadingError={loadingError}
      membershipLevel={membershipLevel}
      modalTitle={modalTitle}
      onActivationCodeClick={handleActivationCodeClick}
      onClickActivate={handleClickActivate}
      onClickEnable={handleAllianceEnable}
      onClickRedeem={handleClickRedeem}
      onClickShowLegal={handleClickShowLegal}
      showAnimation={showAnimation}
      text={text}
      title={title}
      toggleModal={handleModal}
    />
  )
}
