import React from 'react'
import { MemoShelf } from '@smu-chile/pkg-unimarc-components'
import { statusPromotionsV2 } from '@smu-chile/pkg-unimarc-components/helpers/promotionsOfShelvesV2'
import { ShelvesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'

interface PromoCombineShelfProps {
  product?: ShelvesData
  quantity?: number
  shelvesInfoRef?: React.MutableRefObject<unknown[]>
  index?: number
  isMobile?: boolean
  isProductFavorite?: (id?: string) => boolean
  handleAdd?: (props) => void
  handleRemove?: (props) => void
  conditionTooltip?: (props) => void
  setQuantity?: (props) => void
  handleOnClick?: (props) => void
  handleChange: (e: unknown, { itemId, source }) => void
  handleToggleFavorite?: (payload?, nextValue?) => Promise<void>
}

export const PromoCombineShelf = ({
  product,
  quantity,
  shelvesInfoRef,
  index,
  isMobile,
  conditionTooltip,
  handleAdd,
  handleChange,
  handleOnClick,
  handleRemove,
  handleToggleFavorite,
  isProductFavorite,
  setQuantity
}: PromoCombineShelfProps) => {
  const importantWidth = isMobile ? 'calc(50% - 5px)' : '166px'
  const {
    isPromotion,
    isPaymentsMethods,
    price,
    pricePromotion,
    ppum,
    offer,
    pricePaymentsMethods
  } = statusPromotionsV2(product)
  const promotion = {
    ...(product?.priceDetail?.promotionalTag || {}),
    backgroundColor: product?.priceDetail?.promotionalTag?.color,
    label: product?.priceDetail?.promotionalTag?.text,
    color: product?.priceDetail?.promotionalTag?.textColor
  }
  const format =
    product?.measurementUnit?.toLowerCase() === 'kg'
      ? product?.['netContentLevelSmall']
      : product?.['netContent']

  const typePromotion = {
    '': 0,
    price: 1,
    percentage: 2,
    mxn: 3,
    mx$: 4,
    'm%n': 5
  }
  const isFavorite = isProductFavorite(product?.productId)

  const handleOnAddQuantity = (props) => {
    handleAdd(props)
    conditionTooltip(true)
    setQuantity(quantity + 1)
  }
  const handleOnRemoveQuantity = (props) => {
    handleRemove(props)
    conditionTooltip(false)
    setQuantity(quantity - 1)
  }
  const handleOnAddFirst = (props) => {
    handleOnClick && handleOnClick(props)
    conditionTooltip(true)
    setQuantity(quantity + 1)
  }

  return (
    <React.Fragment key={product?.itemId}>
      <MemoShelf
        {...{ ...product, format }}
        altText={product.name}
        catchShelfRef={(ref) => {
          if (!ref) return

          shelvesInfoRef.current = [
            ...shelvesInfoRef.current,
            { ref, product, position: index + 1 }
          ]
        }}
        couponChannel={product?.coupon?.entry?.fields?.channel}
        handleOnClick={handleOnAddFirst}
        img={product?.item?.images?.[0]}
        importantWidth={importantWidth}
        inOffer={product?.sellers?.[0]?.inOffer}
        isFavorite={isFavorite}
        isMobile={isMobile}
        isMporcentageNPromo={product?.priceDetail?.promotionType === 'm%n'}
        isMxNPromotion={product?.priceDetail?.promotionType === 'mxn'}
        isPaymentsMethods={isPaymentsMethods}
        isPromotion={isPromotion}
        itemId={product?.item?.itemId}
        itemRequired={product?.priceDetail?.itemsRequiredForPromotion}
        likeListButtons={false}
        listPrice={product?.priceDetail?.listPrice}
        maxWidthShelf={importantWidth}
        membershipData={product?.priceDetail?.membership}
        offer={offer}
        onToggleFavorite={handleToggleFavorite}
        orientation='vertical'
        ppum={`(${ppum})`}
        ppumListPrice={product?.priceDetail?.ppumListPrice}
        price={price}
        priceDetail={product?.priceDetail}
        pricePaymentsMethods={pricePaymentsMethods}
        pricePromotion={pricePromotion}
        promotion={promotion}
        quantityButtonProps={{
          ...product?.['quantityButtonProps'],
          handleAdd: handleOnAddQuantity,
          handleRemove: handleOnRemoveQuantity,
          handleChange
        }}
        quantityButtonStyles={{
          fontSize: 'md',
          maxHeight: '30px',
          maxWidth: '175px',
          sizeCircle: 22
        }}
        saving={product?.priceDetail?.promotionMessage}
        showAddToFavoriteButton={true}
        showAddToListButton={true}
        showTooltip={false}
        title={product?.['item']?.name || product?.name}
        typePromotion={typePromotion[product?.priceDetail?.promotionType]}
        url={product?.detailUrl}
        version={2}
      />
    </React.Fragment>
  )
}
