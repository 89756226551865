import { hideUsedButton } from '@smu-chile/pkg-unimarc-components/stories/organisms/Shelf/v2/helpers'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useSearchesBySlugV2
} from '@smu-chile/pkg-unimarc-hooks'
import { IErrorNewBFF } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IErrorNewBFF'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { ISearchBySlugV2Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ISearchesBySlugV2'
import { CouponModalProps } from 'components'
import { changeProductData } from 'components/ProductDetailPage/helpers'
import { REACT_QUERY_GENERAL } from 'shared/constants/reactQuery'
import { normalizeCategory } from 'shared/helpers'

type TProductDataInfo = {
  productSearchBySlugV2Data: ISearchBySlugV2Response & IErrorNewBFF
  products: ISearchBySlugV2Response['products']
  categories: ISearchBySlugV2Response['products'][0]['item']['categories']
  formatedData: Product
  productImage: string[]
  productImageSize: string
  isNonFoodType: boolean
  productEan: string
  isValidEan: boolean
}
type TProductPromotionInfo = {
  itemsRequiredForPromotion: number
  isProductCouponUsed: boolean
}
type TProductSearchBySlugV2QueryInfo = {
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<unknown, unknown>>
}

export type TUseProductDetailModal = {
  productDataInfo: TProductDataInfo
  productPromotionInfo: TProductPromotionInfo
  productSearchBySlugV2QueryInfo: TProductSearchBySlugV2QueryInfo
}

/**
 * @name useProductDetailModal
 * @function useProductDetailModal
 * @param {slug: string, productCouponModal: CouponModalProps} props - each of the options that the custom hook accepts
 * @description get product data and proccess it from useSeachesBySlugV2
 * */

export const useProductDetailModal = (
  slug: string,
  productCouponModal: CouponModalProps
): TUseProductDetailModal => {
  // extract product data from slug
  const formatedSlug = slug?.replace(/\/p+$/, '').substring(1)
  const {
    data: productSearchBySlugV2Data,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useSearchesBySlugV2({
    slug: formatedSlug,
    reactQuery: {
      queryKey: ['searchesBySlug', formatedSlug],
      ...REACT_QUERY_GENERAL
    }
  })
  // get product data
  const products = productSearchBySlugV2Data?.products
  // format product data to old structure
  const { data: formatedData } = changeProductData({
    ...products?.[0]
  })
  const productEan = formatedData?.ean
  const isValidEan = productEan?.length > 0
  // get categories
  const categories =
    productSearchBySlugV2Data?.products?.[0]?.item?.categories?.[0]
      ?.split('/')
      ?.map((category) => {
        return normalizeCategory(category)
      })
      ?.filter((isEmpty) => {
        return isEmpty != ''
      })
  // is a non food type product
  const nonFoodCategoryTypes: string[] = ['velas y fósforos', 'hogar']
  const isNonFoodType = categories?.some((category) => {
    const categoryLower: string = category?.toLowerCase()
    return nonFoodCategoryTypes?.includes(categoryLower)
  })
  // get coupon status
  const isProductCouponUsed = hideUsedButton(
    productCouponModal?.couponStatus,
    true
  )
  // get product image information
  const productImage = formatedData?.['images']?.slice(0, 4)
  const productImageSize = formatedData?.images?.length > 1 ? '350px' : '250px'
  // get items required for promotion property
  const itemsRequiredForPromotion =
    formatedData?.priceDetail?.itemsRequiredForPromotion

  return {
    productDataInfo: {
      productSearchBySlugV2Data,
      products: productSearchBySlugV2Data?.products,
      categories,
      formatedData,
      productImage,
      productImageSize,
      isNonFoodType,
      productEan,
      isValidEan
    },
    productSearchBySlugV2QueryInfo: {
      isLoading,
      isError,
      isFetching,
      refetch
    },
    productPromotionInfo: {
      itemsRequiredForPromotion,
      isProductCouponUsed
    }
  }
}
