import {
  TagLoginRef,
  tagLoginView,
  useEvents
} from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'
import { useState } from 'react'

export const useAuthWrapper = () => {
  const router = useRouter()
  const [tagLoginRef, setTagLoginRef] = useState<TagLoginRef>(undefined)
  const [loginModalOpen, setLoginModalOpen] = useState(false)

  useEvents({
    eventType: 'authWrapperOpen',
    callBack: (e: CustomEvent) => {
      setLoginModalOpen(e?.detail?.isOpen)
    }
  })

  const handleOpenLoginModal = (open = true, ref?: TagLoginRef) => {
    const url = new URL(window.location.href)
    if (open && ref) {
      tagLoginView(ref)
    }
    setTagLoginRef(ref)

    if (open) {
      url.searchParams.append('login', 'true')
      router.push(
        {
          pathname: url.pathname.toString(),
          query: url.searchParams.toString()
        },
        undefined,
        {
          shallow: true
        }
      )
    } else {
      url.searchParams.delete('login')
      router.replace(
        {
          pathname: url.pathname.toString(),
          query: url.searchParams.toString()
        },
        undefined,
        {
          shallow: true
        }
      )
    }
  }
  return {
    handleOpenLoginModal,
    tagLoginRef,
    setLoginModalOpen,
    loginModalOpen
  }
}
