import { useEffect } from 'react'
import { DesktopModalWrapper } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import styles from './SideModal.module.css'

interface SideModalProps {
  children?: React.ReactNode
  isOpen?: boolean
  width?: string
  handleShowModal?: () => void
}

export const SideModal = ({
  children,
  isOpen,
  width = '440px',
  handleShowModal
}: SideModalProps) => {
  const handleClose = async () => {
    const sideModal = document.getElementById('sideModal__container')
    sideModal.classList.remove(styles['show'])
    await sleep(500)
    handleShowModal()
  }

  useEffect(() => {
    const animation = async () => {
      await sleep(100)
      const sideModal = document.getElementById('sideModal__container')
      sideModal.classList.add(styles['show'])
    }
    isOpen && animation()
  }, [isOpen])

  return (
    <DesktopModalWrapper
      blockId={getBemId('sideModal', 'modal')}
      bodyChildrenProps={{
        minHeight: '100px',
        maxHeight: 'calc(100vh - 59px)',
        overflow: 'hidden',
        customClassName: styles['modal__container'],
        padding: '0'
      }}
      borderEdge='bottom'
      footerChildrenProps={{
        default: false
      }}
      hasfooter={false}
      headerCloseIcon={{
        default: true,
        name: 'CloseThin',
        sizes: '2xs'
      }}
      headerContainerProps={{
        style: {
          minHeight: '68px',
          padding: '0 20px'
        }
      }}
      headerTitle={'Combina la promo'}
      headerTitleProps={{
        style: {
          fontSize: getGlobalStyle('--font-size-base'),
          fontWeight: '600'
        }
      }}
      modalConfigsProps={{
        containerId: getBemId('sideModal', 'container'),
        customContainerClassName: styles['modal__overlay'],
        isOpen: isOpen,
        minHeight: 'calc(100vh - 16px)',
        toggle: handleClose,
        toggleOutside: handleClose,
        isWindowBlocked: true,
        maxWidth: width
      }}
    >
      {children}
    </DesktopModalWrapper>
  )
}
