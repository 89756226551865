import {
  useEvents,
  useSession,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { useEffect, useState } from 'react'

/**
 * Custom hook for managing header wrapper component functionality.
 * @param {cartData} - Cart data containing pricing information
 *
 * @returns {object} An object containing:
 * - isVisible - Boolean indicating if overlay is visible
 * - setIsVisible - Function to control visibility state of overlay
 * - useUpdateProps - Properties from useUpdateFromShelf hook
 * - isOrderFormLoading - Boolean indicating if the order form is currently loading
 * - total - Formatted total price string from cart
 */
export const useHeaderWrapper = ({ cartData }: { cartData?: ICart }) => {
  const { isLoggedIn } = useSession()

  const [hasLocalAddress, setHasLocalAddress] = useState(false)
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [total, setTotal] = useState('')

  const { editTempOrderForm, ...useUpdateProps } = useUpdateFromShelf({
    fromWhere: 'search',
    isOrderFormLoading,
    hasLocalAddress
  })

  useEvents({
    eventType: 'headerWrapper',
    callBack: (e: CustomEvent) => {
      setIsVisible(e?.detail?.isOpen)
    }
  })

  // When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      editTempOrderForm(items, loading)
      setIsOrderFormLoading(loading)
    }
  })

  useEvents({
    eventType: 'localAddressStoreLocator',
    callBack: ({ detail: { hasLocalAddress: hookHasLocalAddress } }) => {
      if (!isLoggedIn) {
        setHasLocalAddress(hookHasLocalAddress)
      }
    }
  })

  useEffect(() => {
    const subtotal = cartData?.subtotal !== '$0' ? cartData?.subtotal : ''
    setTotal(subtotal)
  }, [cartData?.subtotal])

  return {
    isVisible,
    setIsVisible,
    useUpdateProps,
    isOrderFormLoading,
    total
  }
}
