import { useEffect, useState } from 'react'

interface IPromoTable {
  id: string
  currentQuantity: number
}

export const usePromoCombine = () => {
  const [promoTable, setPromoTable] = useState<IPromoTable[]>([])

  const getPromotionQuantity = (id: string) => {
    const promoTableLocal = sessionStorage.getItem('promotionCombineTable')
    if (!promoTableLocal) return undefined

    return JSON.parse(promoTableLocal).find((promo) => {
      return promo.id === id
    })?.currentQuantity
  }

  const addToPromotion = (id: string, quantity: number) => {
    const promoTableLocal = sessionStorage.getItem('promotionCombineTable')
    let newPromoTable = [
      {
        id,
        currentQuantity: quantity
      }
    ]
    if (promoTableLocal) {
      const promotion = JSON.parse(promoTableLocal).find((promo) => {
        return promo.id === id
      })
      if (promotion) {
        promotion.currentQuantity =
          promotion.currentQuantity + quantity > 0
            ? promotion.currentQuantity + quantity
            : 0

        newPromoTable = [
          ...JSON.parse(promoTableLocal).filter((promo) => {
            return promo.id !== id
          }),
          promotion
        ]
      } else {
        newPromoTable = [...JSON.parse(promoTableLocal), ...newPromoTable]
      }
    }
    if (id) {
      setPromoTable(newPromoTable)
      sessionStorage.setItem(
        'promotionCombineTable',
        JSON.stringify(newPromoTable)
      )
    }
  }

  useEffect(() => {
    if (
      window &&
      sessionStorage.getItem('promotionCombineTable') &&
      promoTable.length === 0
    ) {
      const localPromoTable = JSON.parse(
        sessionStorage.getItem('promotionCombineTable')
      )
      setPromoTable(localPromoTable)
    }
  }, [])

  return {
    promoTable,
    addToPromotion,
    getPromotionQuantity
  }
}
