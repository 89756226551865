import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import {
  IHeaderContentful,
  IHeaderData
} from 'shared/interfaces/IHeaderContentful'
import { headerWrapperQuery } from 'shared/queries'

/**
 * Custom hook for fetching headerV3 data from Contentful
 * @returns {object} An object containing:
 *  - data - The processed header data from Contentful
 *  - isLoading - Boolean indicating whether the data is still being fetched
 */
export const useHeaderContentful = (): {
  data: IHeaderData
  isLoading: boolean
} => {
  const { data: headerDataContentful, isLoading } = useContentful({
    id_contentful: 'header',
    graphQL: {
      query: headerWrapperQuery
    }
  }) as IHeaderContentful

  const data = headerDataContentful?.data?.headerWeb

  return {
    data,
    isLoading
  }
}
