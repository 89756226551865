export interface Form {
  name: string
  formId: string
  type: string
  title: string
  titleColor?: string
  backgroundColor?: string
  labelsColor?: string
  formSections: FormSection[]
  actionButton: ActionButton
  asideImage: ImageAsset
  successImage: ImageAsset
}

export interface ImageAsset {
  fields: {
    file: {
      url: string;
    },
    title: string;
  }
}

export interface FormSection {
  name: string
  sectionId: string
  layout: string
  title?: string
  footer?: string
  formRows: FormRow[]
}

export interface FormRow {
  name: string
  title?: string
  footer?: string
  formFields?: FormField[]
}

export interface FormField {
  name: string
  type: FormFieldTypes
  formfieldId: string
  placeHolder?: string
  label?: string
  defaultValue?: string
  isRequired?: boolean
  isReadOnly?: boolean
  isHidden?: boolean
  validations?: ValidationsType
  minValue?: number
  maxValue?: number
  helpMessage?: string
  errorMessage?: string
}

export interface ActionButton {
  title: string
  platform: string
  showbuttom: boolean
  showLabel: boolean
  labelNewText: string
}

export enum FormFieldTypes {
  Input = 'Input',
  Switch = 'Switch'
}

export enum ValidationsType {
  OnlyText = 'Only Text',
  OnlyNumbers = 'Only Numbers',
  Email = 'Email',
  RUT = 'RUT',
  PhoneNumber = 'Phone Number'
}
