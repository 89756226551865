import { useRouter } from 'next/router'
import {
  Cookies,
  IContentfulResponse,
  datalayerSimpleEvent,
  getRegisterUrl,
  logoutV2,
  useAlliances,
  useEvents,
  useLocalStorage,
  useSession,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import {
  Column,
  Container,
  Picture,
  Row
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ClubAhorroContent } from './ClubAhorroContent'
import { useEffect, useState } from 'react'
import { AllianceModalHandler } from 'components/AlliancePage/AllianceModal'
import { ClubUnimarcWelcomeModal } from './ClubUnimarcWelcomeModal'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { ClubUnimarcQuickAccess } from './ClubUnimarcQuickAccess'
import { ClubUnimarcStaticBanner } from 'shared/interfaces/ClubUnimarc'
import { useClubPage } from '../ClubPage/helpers/useClubPage'
import { ClubContent } from 'components/ClubPage/components/ClubContent'
import { ClubBackbone } from 'components/ClubPage/components/ClubBackbone'
import { ClubUnimarcLoggedIn } from './ClubUnimarLoggedIn/ClubUnimarcLoggedIn'
import { useCheckUserData } from '@smu-chile/pkg-unimarc-hooks'

export interface ClubAhorroProps {
  /** @deprecated */
  banner?: ClubUnimarcStaticBanner
  data: IContentfulResponse
  dataCFMembershipLeves: IContentfulResponse
  dataCFSaving: IContentfulResponse
  isMobile: boolean
  version: string
}

export const ClubAhorro = ({
  data,
  dataCFMembershipLeves,
  dataCFSaving,
  isMobile,
  version
}: ClubAhorroProps) => {
  const router = useRouter()
  const dataUseSession = useSession()
  const { data: userMembership, isLoading: isUserMembershipLoading } =
    useUserWithMembership()
  const { isLoggedIn, isLoading } = dataUseSession
  const { items } = data
  const [isOpen, setIsOpen] = useState(false)
  const [isUserMissingData, setIsUserMissingData] = useState(false)
  const [loginModalStep, setLoginModalStep] = useState<string>(undefined)
  const [allianceCoupons, setAllianceCoupons] = useState([])
  const [selectedAlliance, setSelectedAlliance] = useState(null)
  const [showAnimation, setShowAnimation] = useState(false)
  const [loadingError, setLoadingError] = useState(false)

  const [showSignIn, setShowSignIn] = useState(false)
  const [tagRef, setTagRef] = useState(null)
  const [shouldCheckUser, setShouldCheckUser] = useState(false)
  const showQuickAccess = false

  /**
   * Check if user is still missing data after closing login.
   */
  useCheckUserData(shouldCheckUser, isUserMissingData, setIsUserMissingData)

  const [showWelcome, setShowWelcome] = useLocalStorage(
    'ClubUnimarc/showWelcome',
    'false'
  )

  const alliances = useAlliances({
    userLevel: userMembership?.level ?? 'clubunimarc',
    reactQuery: {
      retry: 0,
      staleTime: 1000 * 60 * 5,
      enabled: !isLoading && !isUserMembershipLoading
    }
  })

  const {
    footerDesktopBlocks,
    hasFooterDesktopBlocks,
    hasHeaderDesktopBlocks,
    hasHeaderMobileBlocks,
    headerDesktopBlocks,
    headerMobileBlocks
  } = useClubPage({
    cfData: data,
    cfDataFilter: 'invitado'
  })

  const getAppView = (isLogged: boolean) => {
    if (!items) {
      return []
    }

    return (
      items.length > 0 &&
      items.filter((item) => {
        const titleEntry = item.viewId as string
        return isLogged
          ? !titleEntry?.includes('invitado')
          : titleEntry?.includes('invitado')
      })
    )
  }

  const [appView] = getAppView(isLoggedIn) ?? []

  const handleModal = (allianceId) => {
    setIsOpen(!isOpen)
    const alliance = allianceCoupons.find((alliance) => {
      return alliance.refid === allianceId
    })
    setSelectedAlliance(selectedAlliance ? null : alliance)
    setTagRef('activacion alianza')
    datalayerSimpleEvent({
      event: 'interacciones_club_unimarc',
      eventAction: 'clic_beneficios',
      eventCategory: 'home',
      eventLabel: `${alliance.refid}`
    })
  }

  const handleSignInModalClose = async () => {
    setShowSignIn(false)
    setShouldCheckUser(true)
  }

  const handleSignInModalOpen = () => {
    setShowSignIn(true)
  }

  // maybe used for user page
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSignUpRedirect = () => {
    window.location.href = getRegisterUrl()
  }

  const handleWelcomeModalClose = () => {
    setShowWelcome('false')
  }

  // maybe used for user page
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleWelcomeModalOpen = () => {
    setShowWelcome('true')
  }

  const handleClickMyBenefits = () => {
    router.push('/club-unimarc/beneficios')
  }

  const handleClickMyCoupons = () => {
    router.push('/club-unimarc/cupones')
  }

  const handleClickBannerSSO = () => {
    !isLoggedIn && handleSignInModalOpen()
  }

  // maybe used for user page
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLogout = () => {
    logoutV2()
  }

  const handleSetTagCoupon = () => {
    setTagRef('activacion cupon')
  }

  const alliancesProps = {
    allianceCoupons: allianceCoupons,
    alliances: alliances,
    isLoggedIn: isLoggedIn,
    isMobile: isMobile,
    isOpen: isOpen,
    loadingError: loadingError,
    membershipLevel: userMembership?.level,
    selectedAlliance: selectedAlliance,
    setAllianceCoupons: setAllianceCoupons,
    setIsOpen: setIsOpen,
    setOpenLoginModal: setShowSignIn,
    setSelectedAlliance: setSelectedAlliance,
    setShowAnimation: setShowAnimation,
    showAnimation: showAnimation
  }

  useEffect(() => {
    const requestUserLevel =
      alliances?.data?.config?.headers?.userlevel ||
      alliances?.data?.request?.requestHeaders?.userlevel ||
      alliances?.data?.request?.rqProxyXhr?._requestHeaders?.userlevel
    if (
      isLoggedIn &&
      userMembership &&
      userMembership.level !== requestUserLevel
    ) {
      alliances.refetch()
    } else if (alliances.isSuccess && alliances?.data?.data?.data?.alliances) {
      setAllianceCoupons(alliances.data.data.data.alliances)
      setLoadingError(false)
    } else if (alliances.isSuccess && alliances.data.data.error) {
      setLoadingError(true)
    }

    if (userMembership?.code === 'USR-23') {
      setIsUserMissingData(true)
    }
  }, [alliances, userMembership])

  useEffect(() => {
    const idTokenCookie = Cookies.get('co_idtoken')
    const refreshTokenCookie = Cookies.get('co_recatoken')
    if (
      dataUseSession.message &&
      dataUseSession.message !== 'OK' &&
      idTokenCookie &&
      refreshTokenCookie
    ) {
      Cookies.set('co_idtoken', '')
      Cookies.set('co_recatoken', '')
      router.reload()
    }
  }, [dataUseSession])

  useEffect(() => {
    setShowSignIn(isUserMissingData)
    setLoginModalStep(isUserMissingData ? 'missingUserData' : 'login')
  }, [isUserMissingData])

  useEvents({
    eventType: 'loginModal',
    callBack: ({ detail: { show } }) => {
      setShowSignIn(show)
    }
  })

  useEffect(() => {
    if (selectedAlliance && isOpen) {
      datalayerSimpleEvent({
        event: 'interacciones_club_unimarc',
        eventAction:
          selectedAlliance.status === 'new' ? 'vista' : 'vista_codigo',
        eventCategory: 'canje_beneficio',
        eventLabel: `${selectedAlliance.refid}`
      })
    }
  }, [isOpen])

  if (isLoading) {
    return <ClubBackbone />
  }

  if (isLoggedIn) {
    return (
      <ClubUnimarcLoggedIn
        allianceProps={alliancesProps}
        alliances={allianceCoupons}
        appView={appView}
        dataCFMembershipLeves={dataCFMembershipLeves}
        dataCFSaving={dataCFSaving}
        isMobile={isMobile}
        onClick={handleModal}
        onClickBanner={handleClickBannerSSO}
        version={version}
      />
    )
  }

  return (
    <Column
      alignItems='center'
      backgroundColor='white'
    >
      {/** desktop */}
      <BigScreen>
        <Column
          alignItems='center'
          gap={80}
        >
          {/** desktop header */}
          {hasHeaderDesktopBlocks && (
            <ClubContent items={headerDesktopBlocks} />
          )}

          {/** desktop middle */}
          <Column
            maxWidth={getGlobalStyle('--width-max-desktop')}
            padding='0 1.25rem'
          >
            <ClubAhorroContent
              alliances={allianceCoupons}
              appView={appView}
              isLoading={!allianceCoupons}
              isMobile={isMobile}
              onClick={handleModal}
              onClickBanner={handleClickBannerSSO}
              version={version}
            />
          </Column>

          {/** desktop footer */}
          {hasFooterDesktopBlocks ? (
            <ClubContent items={footerDesktopBlocks} />
          ) : (
            <span />
          )}
        </Column>
      </BigScreen>

      <Container>
        <Column>
          {/** mobile */}
          <SmallScreen>
            <Column>
              {/** mobile header */}
              {hasHeaderMobileBlocks && (
                <ClubContent items={headerMobileBlocks} />
              )}

              <Column
                maxWidth='100%'
                padding='16px'
              >
                {showQuickAccess && (
                  <Row
                    gap={16}
                    margin='16px 0 0'
                  >
                    <ClubUnimarcQuickAccess
                      image={
                        <Column
                          clickable='inherit'
                          id={getBemId('clubPage', 'quickAccess')}
                          margin='10px 0'
                          width='auto'
                        >
                          <Picture
                            hasURL={true}
                            height='50px'
                            src='https://images.ctfassets.net/un6yvtd6uq5z/24WkypOI7idc8SdiTY9tr6/b646f34dfded12859a765ad16cb8c1f4/image.png'
                            width='50px'
                          />
                        </Column>
                      }
                      isLoading={isLoading}
                      onClick={handleClickMyBenefits}
                      title='Mis beneficios'
                    />
                    <ClubUnimarcQuickAccess
                      icon='Coupon6'
                      isLoading={isLoading}
                      onClick={handleClickMyCoupons}
                      title='Mis cupones'
                    />
                  </Row>
                )}

                {/** mobile middle */}
                <ClubAhorroContent
                  alliances={allianceCoupons}
                  appView={appView}
                  isMobile={isMobile}
                  onClick={handleModal}
                  onClickBanner={handleClickBannerSSO}
                  setTagCoupon={handleSetTagCoupon}
                />
              </Column>
            </Column>
          </SmallScreen>

          {showWelcome === 'true' && (
            <ClubUnimarcWelcomeModal
              isLogged={isLoggedIn}
              isMobile={isMobile}
              onButtonClick={handleWelcomeModalClose}
              onIconCloseClick={handleWelcomeModalClose}
              pictureUrl={appView?.welcomeModal?.['brandClubLogo']}
            />
          )}

          {showSignIn && (
            <LoginModalBrowse
              defaultFormStep={loginModalStep}
              isOpen={showSignIn}
              onClose={handleSignInModalClose}
              router={router}
              tagLoginRef={tagRef || 'boton-ingresar'}
            />
          )}

          <AllianceModalHandler
            allianceCoupons={allianceCoupons}
            alliances={alliances}
            isLoggedIn={isLoggedIn}
            isMobile={isMobile}
            isOpen={isOpen}
            loadingError={loadingError}
            membershipLevel={userMembership?.level}
            selectedAlliance={selectedAlliance}
            setAllianceCoupons={setAllianceCoupons}
            setIsOpen={setIsOpen}
            setOpenLoginModal={setShowSignIn}
            setSelectedAlliance={setSelectedAlliance}
            setShowAnimation={setShowAnimation}
            showAnimation={showAnimation}
          />
        </Column>
      </Container>

      <LoginModalBrowse
        defaultFormStep={loginModalStep}
        isOpen={showSignIn}
        onClose={handleSignInModalClose}
        router={router}
        tagLoginRef={tagRef || 'boton-ingresar'}
      />
    </Column>
  )
}
