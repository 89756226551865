import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { priceStringToNumber } from './priceStringToNumber'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { IProductsSearchPaymentMethodAndMembership } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsSearch'
import { Icons } from '@smu-chile/pkg-unimarc-components/assets/SvgComponents/SvgIcons'

export interface PromotionReturn {
  isPromotion: boolean
  isPaymentsMethods?: boolean
  price: number | string
  pricePromotion: boolean
  pricePaymentsMethods?: IProductsSearchPaymentMethodAndMembership
  ppum: string
  offer: string
}

export const statusPromotions = (product: Product): PromotionReturn => {
  let isPromotion = false
  let price: number | string = product?.sellers?.[0]?.price
  let pricePromotion = false
  let ppum: string = product?.sellers?.[0]?.ppum
  let offer: string
  let isPaymentsMethods = false
  let pricePaymentsMethods: IProductsSearchPaymentMethodAndMembership

  if (product?.promotion?.['hasSavings']) {
    price = parseInt(
      product?.promotion?.['descriptionMessage']?.replace(/[$]|[.]/g, '')
    )
    ppum =
      product?.measurementUnit?.toLowerCase() === 'kg'
        ? ppum
        : product?.promotion?.['ppum']

    //Nxp
    if (product?.promotion?.['descriptionCode'] === 4) {
      isPromotion = true
      const messagePromo = product?.promotion['descriptionMessage']
      const untilIndex = messagePromo.indexOf('x')
      const amountProductIndex = messagePromo.indexOf('$')
      price = parseInt(
        messagePromo
          .slice(amountProductIndex + 1, messagePromo.length + 1)
          ?.replace('.', '')
      )

      offer = messagePromo.slice(0, untilIndex + 1)
    }

    //Mxn
    if (product?.promotion?.['descriptionCode'] === 3) {
      isPromotion = true
      offer = product?.promotion?.['saving']
    }

    //porcentual
    if (product?.promotion?.['descriptionCode'] === 2) {
      price = product?.promotion?.['price']
      const porcent = getPorcent(
        priceStringToNumber(product?.sellers?.[0]?.listPrice.toString()),
        parseInt(price.toString())
      )
      isPromotion = true
      offer = `${porcent}%`
    }

    //porcentual code 1
    if (product?.promotion?.['descriptionCode'] === 1) {
      price = product?.sellers[0]?.price
      const porcent = getPorcent(
        product?.sellers[0]?.listPrice,
        parseInt(price.toString())
      )
      isPromotion = porcent >= 5
      offer = `${porcent}%`
    }
  } else if (
    product?.sellers?.[0]?.listPrice !== product?.sellers?.[0]?.price
  ) {
    price = product?.sellers[0]?.price
    const porcent = getPorcent(
      priceStringToNumber(product?.sellers?.[0]?.listPrice.toString()),
      priceStringToNumber(product?.sellers[0]?.price.toString())
    )
    pricePromotion = porcent >= 5
    offer = `${porcent}%`
  }

  // Banco Estado & Unipay
  if (product?.promotion?.['pricePaymentsMethods']?.length > 0) {
    isPaymentsMethods = true
    pricePaymentsMethods = product?.promotion?.['pricePaymentsMethods'][0]
  }

  return {
    isPromotion,
    isPaymentsMethods,
    offer,
    ppum,
    price,
    pricePromotion,
    pricePaymentsMethods
  }
}

const getPorcent = (
  listPrice: number | string,
  priceDiscount: number
): number => {
  const parseListPrice = parseInt(listPrice.toString())
  return Math.round(100 - (priceDiscount * 100) / parseListPrice)
}

/**
 * get promotions from the shelves.
 * @param {Object} param0 Object that contains the data.
 * @param {Object} param0.data API data.
 * @returns {Array} Shelve Promotions.
 */
export const promotionsOfShelves = ({ data }) => {
  // Get the campaigns from the shelf fields
  const getCampaignsFromShelves = ({ fields, data }) => {
    return fields?.campaings?.map(({ sys }) => {
      // Find the linked entry
      const linkedEntry = data?.includes?.Entry?.find((entry) => {
        return entry.sys.id === sys.id
      })
      const linkedEntryFields = linkedEntry?.fields || {}

      // If there is a linked campaign icon, get its fields
      if (linkedEntryFields.campaignIcon) {
        const linkedAsset = data?.includes?.Asset?.find((asset) => {
          return asset.sys.id === linkedEntryFields.campaignIcon.sys.id
        })
        if (linkedAsset) {
          linkedEntryFields.campaignIcon = linkedAsset.fields
        }
      }

      return { id: sys.id, ...linkedEntryFields }
    })
  }

  // Filter campaigns based on format
  const filterCampaignsByFormat = (campaigns) => {
    return campaigns
      .map((fields) => {
        if (fields.idFormato === 1) {
          return {
            ...fields
          }
        }
        return null
      })
      .filter((item) => {
        return item !== null
      })
  }

  // Get shelf campaigns
  const shelvesCampaigns = data?.items?.map(({ fields }) => {
    return getCampaignsFromShelves({ fields, data })
  })

  // Filter campaigns based on format and flatten the array
  const filteredAndFlattenedCampaigns =
    isValidArrayWithData(shelvesCampaigns) &&
    shelvesCampaigns.flatMap(filterCampaignsByFormat)

  return filteredAndFlattenedCampaigns
}

export type TGetDataPromotion = {
  backgroundColor: string
  borderRadius?: string
  campaignId?: string
  color: string
  fontSize?: number
  fontWeight?: string
  headerLabel?: boolean
  height?: string
  icon?: keyof typeof Icons
  isMembership?: boolean
  isMobile?: boolean
  label: string
  lineHeight?: string
  padding?: string
  place?: string
  positionIcon?: 'left' | 'right'
  provider?: string
  width?: string
}
export const getDataPromotion = (
  dataPromotions,
  promotionId
): TGetDataPromotion | null => {
  let promotion = null

  if (dataPromotions) {
    const promotionTemp = dataPromotions?.find((dataPromotion) => {
      return promotionId == dataPromotion?.['campaignId']
    })

    if (promotionTemp) {
      promotion = {
        backgroundColor: promotionTemp?.['color'],
        color: promotionTemp?.['textColor'],
        label: promotionTemp?.['campaignName'],
        image: promotionTemp?.['campaignIcon']?.file?.url,
        campaignId: promotionId
      }
    }
  }

  return promotion
}
