import {
  getContentfulDesktopImage,
  getContentfulImageCategories,
  useContentful,
  useMobile
} from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useRef, useState } from 'react'
import { OFFERS_URL } from 'shared/utils/constanst'

/**
 * Hook that handles all the logic for CategoriesWrapper component
 * @function useCategoriesWrapper
 * @returns {Object} An object containing the following properties:
 * @returns {Function} handleOpen - Function to control the open/close state of the categories wrapper
 * @returns {boolean} isOpen - Current state indicating if the categories wrapper is open
 * @returns {React.RefObject} ref - Reference to the wrapper element for detecting outside clicks
 * @returns {Object} categoriesDataImage - Contains the processed categories images from Contentful
 * @returns {Object} categoriesBannerImage - Contains the desktop banner images from Contentful
 * @returns {Object} offersMobile - Contains offers data for mobile view:
 *   - url: {string} Link to offers page
 *   - img: {string} URL of the offers image
 */
export const useCategoriesWrapper = () => {
  const ref = useRef(null)
  const { isMobileV2: isMobile } = useMobile()
  const [isOpen, setIsOpen] = useState(false)

  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE
    }
  })
  const offersLink = useContentful({
    id_contentful: 'offersLink',
    options: {
      'sys.contentType.sys.id[in]': 'home',
      'sys.id[in]': OFFERS_URL
    }
  })
  const imgOfOffers = useContentful({
    id_contentful: 'offers_img',
    options: {
      'fields.title[in]': 'Ofertas mobile'
    },
    reactQuery: {
      enabled: isMobile
    },
    type: 'assets'
  })

  const categoriesDataImage =
    categoriesApp?.data?.['items']?.length > 0
      ? getContentfulImageCategories(categoriesApp?.data)
      : {}
  const categoriesBannerImage =
    categoriesApp?.data?.['items']?.length > 0
      ? getContentfulDesktopImage(categoriesApp?.data)
      : {}

  const linkOffers =
    offersLink.data?.['includes']?.['Entry']?.[0]?.fields?.text ||
    process.env.NEXT_PUBLIC_OFFERSURL

  const offersMobile = {
    url: linkOffers,
    img: imgOfOffers.data?.['items']?.[0]?.fields?.file?.url
  }

  const handleOpen = (state: boolean) => {
    setIsOpen(state)
  }

  const handleClickOutside = (event: CustomEvent) => {
    // filter click on header button
    const element = document.getElementById('headerButton__CategoriesHeader')
    if (element?.contains(event.target as Node)) return
    // close when click outside
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside)

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside)
    }
  }, [])

  return {
    handleOpen,
    isOpen,
    ref,
    categoriesDataImage,
    categoriesBannerImage,
    offersMobile
  }
}
